import React from 'react';
import { Card, CardHeader, CardTitle, CardBody, Row, Col } from 'reactstrap';
import YouTubeEmbed from 'components/YouTubeEmbed';

function Help() {
  return (
    <div className='content'>
      <Row>
        <Col>
          <Card className='card-user'>
            <CardHeader>
              <Row>
                <Col sm={8}>
                  <CardTitle tag='h4'>Ayuda</CardTitle>
                </Col>
                <Col sm={4}></Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row className="pt-4 pb-4">
                <Col sm={6}>
                  <h5>Gestión de Autorización Presenciales</h5>
                  <div className="mb-4 font-weight-bold">
                    <a
                      href="/pdf/manual_Gestión de Autorización presenciales.pdf"
                      target="_blank"
                      rel="noopener noreferrer">Ver Manual</a>
                  </div>
                </Col>
                <Col sm={6}>
                  <h5>Gestión de Autorización OSPe</h5>
                  <div className="mb-4 font-weight-bold">
                    <a href="/pdf/manual_ospe.pdf" target="_blank" rel="noopener noreferrer">Ver Manual</a>
                  </div>
                </Col>
              </Row>
              <Row className="pt-4 pb-4">
                <Col sm={6}>
                  <h5>Gestión de Autorización Swiss Medical</h5>
                  <div className="mb-4 font-weight-bold">
                    <a
                      href="/pdf/manual_swiss.pdf"
                      target="_blank"
                      rel="noopener noreferrer">Ver Manual</a>
                  </div>
                </Col>
              </Row>
              <Row className="pt-4 pb-4">
                <Col sm={6}>
                  <h5>Gestión de Autorización Sancor Salud</h5>
                  <div className="mb-4 font-weight-bold">
                    <a
                      href="/pdf/manual_sancor.pdf"
                      target="_blank"
                      rel="noopener noreferrer">Ver Manual</a>
                  </div>
                </Col>
              </Row>
              <Row className="pt-4 pb-4">
                <Col sm={6}>
                  <h5>Gestión de Autorización y Facturación Online</h5>
                  <div className="mb-4 font-weight-bold">
                    <a
                      href="/pdf/manual_Gestión de Autorización y Facturación online.pdf"
                      target="_blank"
                      rel="noopener noreferrer">Ver Manual</a>
                  </div>
                  <YouTubeEmbed embedId="2fEBFY9ZKbs" />
                </Col>
                <Col sm={6}>
                  <h5>APP de Escaneo en dispositivo Móvil</h5>
                  <div className="mb-4 font-weight-bold">
                    <a
                      href="/pdf/app.doc"
                      target="_blank"
                      rel="noopener noreferrer">Ver Manual</a>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Help;
