import React, { useState, useEffect, ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  FormGroup,
  Input,
  Label,
  CardFooter,
  Button,
  Row,
  Col } from 'reactstrap';
import { getAll, post } from 'services/ApiService';
import { useAlert } from 'hooks';
import { useStore } from '../../context/StoreContext';
import AlertModal from 'components/AlertModal';
import { Insurer } from 'types/Request';

export default function StepInsurerSelection() {
  const [formData, setFormData] = useState({
    insurer_id: -1,
    insurer_name: '',
    previously_authorized: false,
  });

  const history = useHistory();

  const { resetSubmission, updateSubmission } = useStore();

  const [insurers, setInsurers] = useState<Insurer[]>([]);

  const [isLoading, setLoading] = useState(true);
  const [showAlert, message, alert] = useAlert(false, '');

  const [hasOfflineAuthorization, setOfflineAuthorization] = useState(false);

  const [hasAffiliateValidation, setAffiliateValidation] = useState(false);

  const [hasAuthorizationPerSession, setAuthorizationPerSession] = useState(false);
  const [hasAuthorizationPerPractice, setAuthorizationPerPractice] = useState(false);

  const [preAuthLabel, setPreAuthLabel] = useState('Autorización Previa Presencial');

  const insurersWithOfflineAuthorization = [
    36, // Avalian (ex ACA Salid)
    66, // Sancor Salud - Formulario 4
    74, // Federada - Solicitud previa
  ];

  const insurersWithAffiliateVerification = [
    66, // Sancor Salud
    74, // Federada
  ];

  const insurersWithAuthorizationPerPractice = [
    36, // Avalian
  ];

  const insurersWithAuthorizationPerSession = [
    57, // Swiss
    74, // Federada
    77, // OSPe
  ];

  const handleInputChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    // onChange de cualquier campo que no sea select
    if (!('options' in target)) {
      setFormData({
        ...formData,
        previously_authorized: target.checked,
      });
      return;
    }

    // onChange de selects
    if ('options' in target) {
      // Sólo hay un select y verificamos que sea el correcto
      if (target.name !== 'insurer_id') {
        return;
      }

      // Recuperamos el id de la ooss seleccionada
      const selectedInsurerId = parseInt(target.value);

      // Cambiamos el id de la obra social y reseteamos algunos valores
      setFormData({
        insurer_id: selectedInsurerId,
        insurer_name: target.options[target.selectedIndex].text,
        previously_authorized: false,
      });

      // Seteamos los flags de la obra social
      setOfflineAuthorization(insurersWithOfflineAuthorization.includes(selectedInsurerId));
      setAuthorizationPerSession(insurersWithAuthorizationPerSession.includes(selectedInsurerId));
      setAuthorizationPerPractice(insurersWithAuthorizationPerPractice.includes(selectedInsurerId));
      setAffiliateValidation(insurersWithAffiliateVerification.includes(selectedInsurerId));

      // Obras sociales que llaman a la autorización previa de forma diferente
      switch (selectedInsurerId) {
        case 66:
          setPreAuthLabel('Formulario 4');
          break;
        case 74:
          setPreAuthLabel('Con Solicitud Autorizada');
          break;
        default:
          setPreAuthLabel('Autorización Previa Presencial');
      }
    }
  };

  const handleSubmit = async () => {
    if (!formData.insurer_id || formData.insurer_id === -1) {
      alert(true, 'Tiene que seleccionar una Obra Social antes de poder seguir con el trámite');
      return;
    }

    // Guardamos la información en el store
    updateSubmission(formData);

    // Obras sociales que requieren validación del afiliado
    if (hasAffiliateValidation) {
      history.push('/solicitudes/step-affiliate-validation');
      return;
    }

    // Obras sociales que autorizan por práctica (conjunto sesiones) siempre y
    // cuando no sea con autorización previa (que es por práctica)
    if (hasAuthorizationPerPractice && !formData.previously_authorized) {
      history.push('/solicitudes/step-online-authorization');
      return;
    }

    // Demás obras sociales y casos creamos una nueva solicitud en blanco
    await post('submissions', {
      insurer_id: formData.insurer_id,
      previously_authorized: hasOfflineAuthorization ? formData.previously_authorized : hasAuthorizationPerSession,
    })
      .then(res => {
        if (!res.id) {
          console.error('StepInsurerSelection', 'No se pudo crear la nueva solicitud con autorización previa');
          console.info(res);
          alert(true, 'Ha ocurrido un error al enviar la solicitud, por favor intente nuevamente.');
          setLoading(false);
          return;
        }

        switch (formData.insurer_id) {
          case 57: // Swiss
            history.push(`/solicitudes/${ res.id }/edit-swiss`);
            break;
          case 74: // Federada
            history.push(`/solicitudes/${ res.id }/edit-federada`);
            break;
          case 77: // Otros con autorización por sesión
            history.push(`/solicitudes/${ res.id }/edit-sessions`);
            break;
          default: // Otros
            history.push(`/solicitudes/${ res.id }/edit`);
        }
      })
      .catch(err => {
        console.error('StepInsurerSelection', 'Ocurrió un error mientras intentaba crear la solicitud');
        console.info(err);
        alert(true, 'Ha ocurrido un error al enviar la solicitud. Por favor intente nuevamente.');
      });
  };

  useEffect(() => {
    resetSubmission();

    getAll('ooss', { limit: 100 })
      .then(res => {
        setInsurers(res.rows);
      })
      .catch(err => {
        console.error('StepInsurerSelection', 'No se pudieron obtener las OOSS');
        alert(true, 'Ha ocurrido un error al obtener el listado de Obras Sociales, ' +
          'por favor intente nuevamente más tarde.');
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="content">
      <Row>
        <Col sm={12}>
          <Card>
            <CardHeader>
              <Row style={{ alignItems: 'center' }}>
                <Col sm={8}>
                  <CardTitle tag='h5'>Elegir Obra Social</CardTitle>
                </Col>
                <Col sm={4}></Col>
              </Row>
            </CardHeader>
            <CardBody>
              <FormGroup>
                <Input
                  label="Obra Social"
                  id="insurer_id"
                  name="insurer_id"
                  type="select"
                  value={ formData.insurer_id }
                  onChange={ handleInputChange }
                  placeholder="Obras sociales"
                  disabled={ isLoading }
                  required>
                  <option disabled value="-1" hidden>Seleccione una opción...</option>
                  {
                    insurers.map(insurer => <option key={ insurer.id } value={ insurer.id }>{ insurer.codigo }</option>)
                  }
                </Input>
              </FormGroup>
              <FormGroup style={{ paddingLeft: '1.5rem' }}>
                <Input
                  id="previously_authorized"
                  name="previously_authorized"
                  type="checkbox"
                  onChange={ handleInputChange }
                  checked={ formData.previously_authorized }
                  disabled={ isLoading || !hasOfflineAuthorization }
                />
                <Label
                  for="previously_authorized"
                  style={{
                    marginBottom: 0,
                    verticalAlign: 'baseline',
                    fontSize: '1rem',
                    color: hasOfflineAuthorization ? '#333333' : '#9A9A9A',
                    width: '100%',
                  }}
                >
                  { preAuthLabel }
                </Label>
              </FormGroup>
            </CardBody>
            <CardFooter className="py-4 text-center">
              <Button
                type="button"
                onClick={ handleSubmit }
                disabled={ isLoading }
                className="mr-4 btn-primary btn-round"
              >
                Aceptar
              </Button>
              <Button
                type="button"
                onClick={() => history.push('/solicitudes') }
                className="btn-round"
              >
                Cancelar
              </Button>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <AlertModal isOpen={ showAlert } message={ message } onClose={ () => alert(false) } />
    </div>
  );
}
