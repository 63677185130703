import React, { ChangeEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { post } from 'services/ApiService';
import { useAlertPlus } from 'hooks';
import { useStore } from '../../context/StoreContext';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row } from 'reactstrap';

import AlertModal from '../../components/AlertModal';

export default function StepAffiliateValidation() {
  const history = useHistory();
  const { store, resetSubmission, updateSubmission } = useStore();

  const [alertShow, alertMessage, alertCallback, alert] = useAlertPlus('');

  const insurerId = store.current_submission.insurer_id;

  const [formData, setFormData] = useState({
    recipient_id: '',
    recipient_id_type: 'afiliado',
    recipient_subid: '00',
    document_type: '1',
  });

  const [recipient, setRecipient] = useState({
    id: '',
    subid: '',
    name: '',
    plan: '',
    tax: '',
  });

  const [isLoading, setLoading] = useState(false);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleVerification = async () => {
    try {
      setLoading(true);

      const response = await post('insurers/validate', {
        ...formData,
        insurer_id: insurerId,
      });

      // Errores
      if (response.status !== 'ok') {
        if (response.message) {
          alert(response.message);
        } else {
          alert('Hubo un problema al intentar validar el afiliado. Por favor, intente nuevamente más tarde');
        }

        setLoading(false);
        return;
      }

      const rawId = response.payload.recipient_id.split('/');
      if (rawId.length === 1 || !rawId[1]) {
        rawId[1] = rawId[0].substring(rawId[0].length - 2);
        rawId[0] = rawId[0].substring(0, rawId[0].length - 2);
      }

      setRecipient({
        id: rawId[0],
        subid: rawId[1],
        name: response.payload.recipient_name,
        plan: response.payload.plan_text,
        tax: response.payload.tax,
      });
    } catch (err) {
      console.error(err);
      alert('Hubo un problema al intentar validar el beneficiario. Por favor, intente nuevamente más tarde');
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmation = async () => {
    try {
      setLoading(true);

      const response = await post('insurers/validate/confirm', {
        insurer_id: insurerId,
        recipient_id: recipient.id,
        recipient_subid: recipient.subid,
        previously_authorized: store.current_submission.previously_authorized,
      });

      // Errores
      if (response.status !== 'ok') {
        if (response.message) {
          alert(response.message);
        } else {
          alert('Hubo un problema al intentar autorizar la práctica. Por favor, intente nuevamente más tarde');
        }
        setLoading(false);
        return;
      }

      const id = response.payload?.billing_request?.id;

      if (!id) {
        alert(`Se produjo un error al intentar crear la solicitud. Por favor, intente nuevamente`, () => {
          history.replace(`/solicitudes`);
        });
      }

      updateSubmission({
        ...store.current_submission,
        request_id: id,
      });

      switch (insurerId) {
        case 66:
          if (store.current_submission.previously_authorized) {
            history.replace(`/solicitudes/step-authorization-validation`);
          } else {
            history.replace(`/solicitudes/${ id }/edit-sancor`);
          }
          break;
        case 74:
          if (store.current_submission.previously_authorized) {
            history.replace(`/solicitudes/step-authorization-validation`);
          } else {
            history.replace(`/solicitudes/${ id }/edit-federada`);
          }
          break;
        default:
          history.replace(`/solicitudes/${ id }/edit-sessions`);
      }
    } catch (err) {
      console.error(err);
      alert('Hubo un problema al intentar validar el número de beneficiario. Por favor, intente nuevamente más tarde');
      setLoading(false);
    }
  };

  const handleTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      recipient_id_type: event.target.value,
    });
  };

  const handleCancelation = () => {
    resetSubmission();
    history.replace('/solicitudes/step-insurer-selection');
    return;
  };

  return (
    <>
      <Container className="content">
        <Card>
          <CardHeader>
            <CardTitle tag='h5'>Validar Afiliado</CardTitle>
            <FormGroup style={{ marginLeft: '20px' }}>
              <FormGroup check>
                <Input
                  type="radio"
                  name="recipient_id_type"
                  onChange={ handleTypeChange }
                  id="afiliado"
                  value="afiliado"
                  checked={ formData.recipient_id_type === 'afiliado' }
                />
                <Label
                  for="afiliado"
                  style={{ paddingLeft: '15px' }}
                  check
                >
                  Por número de afiliado
                </Label>
              </FormGroup>
              <FormGroup check>
                <Input
                  type="radio"
                  name="recipient_id_type"
                  onChange={ handleTypeChange }
                  id="documento"
                  value="documento"
                  checked={ formData.recipient_id_type === 'documento' }
                />
                <Label
                  for="documento"
                  style={{ paddingLeft: '15px' }}
                  check
                >
                  Por número de documento
                </Label>
              </FormGroup>
            </FormGroup>
          </CardHeader>
          <CardBody>
            <FormGroup row>
              {
                formData.recipient_id_type === 'afiliado'
                  ? <Col sm={ 12 } md={ 4 }>
                      <Label for="recipient_id">N° de Afiliado</Label>
                      <InputGroup>
                        <Input
                          id="recipient_id"
                          name="recipient_id"
                          type="number"
                          step="1"
                          min="1"
                          max="9999999999"
                          pattern="\d+"
                          style={{ width: '35%', textAlign: 'right' }}
                          value={ formData.recipient_id }
                          onChange={ handleInputChange }
                          disabled={ isLoading }
                        />
                        <InputGroupText style={{
                          backgroundColor: 'transparent',
                          borderRadius: 0,
                          borderLeft: 'none',
                          borderRight: 'none',
                        }}>
                          /
                        </InputGroupText>
                        <Input
                          id="recipient_subid"
                          name="recipient_subid"
                          type="number"
                          step="1"
                          min="1"
                          max="999"
                          pattern="\d+"
                          value={ formData.recipient_subid }
                          onChange={ handleInputChange }
                          disabled={ isLoading }
                        />
                      </InputGroup>
                    </Col>
                  : null
              }
              {
                formData.recipient_id_type === 'documento'
                  ? <>
                      <Col sm={ 6 } md={ 3 }>
                        <Label for="document_type">Tipo de Documento</Label>
                        <Input
                          type="select"
                          id="document_type"
                          name="document_type"
                          onChange={ handleInputChange }
                          disabled={ isLoading || insurerId !== 74}>
                          <option value="1">DNI</option>
                          <option value="2">Libreta de enrolamiento</option>
                          <option value="3">Libreta cívica</option>
                          <option value="5">Pasaporte</option>
                          <option value="7">Documento extranjero</option>
                          <option value="8">Documento recién nacido (en trámite)</option>
                        </Input>
                      </Col>
                      <Col sm={ 6 } md={ 3 }>
                        <Label for="recipient_id">N° de Documento</Label>
                        <Input
                          id="recipient_id"
                          name="recipient_id"
                          type="number"
                          step="1"
                          min="1"
                          max="9999999999"
                          pattern="\d+"
                          style={{ textAlign: 'right' }}
                          value={ formData.recipient_id }
                          onChange={ handleInputChange }
                          disabled={ isLoading }
                        />
                      </Col>
                    </>
                  : null
              }
              <Col sm={ 12 } md={ 2 }>
                <Label>&nbsp;</Label><br />
                <Button
                  color="primary"
                  style={{ margin: 0 }}
                  onClick={ handleVerification }
                  disabled={ isLoading }
                >
                  Validar
                </Button>
              </Col>
            </FormGroup>
            <Row className={ recipient.id ? 'd-block' : 'd-none' }>
              <Col sm={ 12 } md={ 6 }>
                <strong>Número de Beneficiario</strong>: { recipient.id } / { recipient.subid }<br />
                <strong>Nombre y Apellido</strong>: { recipient.name }<br />
                <strong>Plan</strong>: { recipient.plan }<br />
                <strong>Condición Impositiva</strong>: { recipient.tax }<br />
              </Col>
              <Col sm={ 6 } style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Button
                  color="danger"
                  disabled={ isLoading }
                  onClick={ handleCancelation }
                >
                  Cancelar
                </Button>
                <Button
                  color="success"
                  onClick={ handleConfirmation }
                  disabled={ isLoading }
                >
                  Confirmar
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
      <AlertModal isOpen={ alertShow } message={ alertMessage } onClose={ alertCallback } />
    </>
  );
}
