import React, { useState, ChangeEvent, useEffect } from 'react';
import { Container, Card, CardHeader, CardTitle, CardBody, Col, Label, Input, Row, Button } from 'reactstrap';
import { useStore } from 'context/StoreContext';
import { post } from 'services/ApiService';
import { getPractices } from 'features/practices/practicesAPI';
import { InsurerPractices } from 'types/Request';
import { useUser } from 'context';
import { useAlertPlus } from 'hooks';
import AlertModal from '../../components/AlertModal';
import Required from 'components/Required';
import { getErrorMessage } from 'helpers/errors';
import { useHistory } from 'react-router-dom';
import DatePicker from 'components/DatePicker';

export default function StepAuthorizationValidation() {
  const { store } = useStore();

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [alertShow, alertMessage, alertCallback, setAlert] = useAlertPlus('');

  const { getMatricula } = useUser();
  const insurerId = store.current_submission.insurer_id;
  const requestId = store.current_submission.request_id;
  const matriculateId = getMatricula();

  if (!insurerId || !matriculateId) {
    history.replace('/solicitudes/step-insurer-selection');
    return;
  }

  const [labelAuth, setLabelAuth] = useState('N° de Autorización');
  const [prestaciones, setPrestaciones] = useState<InsurerPractices>({
    modules: [],
    additionals: [],
  });

  const [formData, setFormData] = useState({
    authorization_id: '',

    prescriber_id: '',
    prescription_date: new Date(),

    practice_id: -1,
    practice_text: '',

    additional_id: -1,
    additional_text: '',
  });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;

    const name = target.name;
    const value = target.value;
    const extra: Partial<typeof formData> = {};

    // Si cambiaron los id de práctica, recuperamos el nuevo nombre
    switch (name) {
      case 'practice_id':
        if (typeof value === 'string') {
          extra['practice_text'] = prestaciones.modules.find(p => p.id === parseInt(value))!.name;
        } else {
          extra['practice_text'] = undefined;
        }
        break;
      case 'additional_id':
        if (typeof value === 'string') {
          extra['additional_text'] = prestaciones.modules.find(p => p.id === parseInt(value))!.name;
        } else {
          extra['additional_text'] = undefined;
        }
        break;
    }

    // Guardamos el nuevo valor
    setFormData({
      ...formData,
      ...extra,
      [name]: value,
    });
  };

  const handleVerification = async () => {
    try {
      setIsLoading(true);

      if (!formData.authorization_id.replace(/\D/g, '')) {
        setAlert('Es obligatorio ingresar el número de formulario para autorizar. Por favor complete el campo e ' +
          'intente nuevamente');
        return;
      }

      const response = await post('insurers/validate-authorization', {
        ...formData,
        prescription_date: formData.prescription_date.toISOString().split('T')[0],
        request_id: requestId,
        insurer_id: insurerId,
      });

      // Errores
      if (response.status !== 'ok') {
        if (response.message) {
          setAlert(response.message);
        } else {
          setAlert('Hubo un problema al intentar validar la autorización. Por favor, intente nuevamente más tarde');
        }

        setIsLoading(false);
        return;
      }

      history.push(`/solicitudes/${ response.payload.id }/edit-sancor-4`);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelation = () => {
    history.push('/solicitudes');
  };

  useEffect(() => {
    switch (insurerId) {
      case 66:
        setLabelAuth('N° de Formulario');
        break;
      case 74:
        setLabelAuth('N° de Solicitud');
        break;
    }
    getPractices({
      insurerId,
      matriculateId: parseInt(matriculateId),
      date: new Date().toISOString().split('T')[0],
    })
      .then(data => {
        if (!data || !data.modules || data.modules.length == 0) {
          throw new Error('Hay problemas de conexión con el sistema de Facturación. ' +
            'Temporalmente no se podrá realizar la validación.');
        }

        // Cargamos las prestaciones
        setPrestaciones({
          modules: data.modules ?? [],
          additionals: data.additionals ?? [],
        });

        // Prestación inicial
        setFormData({
          ...formData,
          practice_id: data.modules[0].id,
          practice_text: data.modules[0].name,
        });

        setIsLoading(false);
      })
      .catch(err => {
        console.error(err);
        setAlert(getErrorMessage(err));
      });
  }, []);

  return (
    <Container className="content">
      <Card>
        <CardHeader>
          <Row>
            <Col md={ 8 }>
              <CardTitle tag='h5'>Validar Autorización</CardTitle>
            </Col>
            <Col md='4' className='back-button-container'>
              <Button className='btn-round' color='primary' onClick={() => history.goBack()}>
                Volver
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm={ 12 } md={ 6 }>
              <Label for="authorization_id">{ labelAuth } <Required /></Label>
              <Input
                id="authorization_id"
                name="authorization_id"
                value={ formData.authorization_id }
                onChange={ handleInputChange }
                disabled={ isLoading }
              />
            </Col>
          </Row>
          <Row>
            <Col sm={ 12 } md={ 6 }>
              <Label for="prescriber_id">Matrícula Médico Prescriptor</Label>
              <Input
                id="prescriber_id"
                name="prescriber_id"
                type="number"
                value={ formData.prescriber_id }
                onChange={ handleInputChange }
                disabled={ isLoading }
              />
            </Col>
          </Row>
          <Row>
            <Col sm={ 12 } md={ 6 }>
              <Label for="prescription_date">Fecha de Prescripción <Required /></Label>
              <DatePicker
                id="prescription_date"
                name="prescription_date"
                selected={ formData.prescription_date ?? '' }
                onChange={ (date: Date | null) => {
                  setFormData({
                    ...formData,
                    prescription_date: date ?? new Date(),
                  });
                }}
                maxDate={ new Date() }
                className="date-picker form-control"
                dateFormat="dd/MM/yyyy"
                locale="es"
                disabled={ isLoading }
                autoComplete="off"
                required
              />
            </Col>
          </Row>
          <Row>
            <Col sm={ 12 } md={ 6 }>
              <Label for="practice_id">Código de Práctica <Required /></Label>
              <Input
                id="practice_id"
                name="practice_id"
                type="select"
                onChange={ handleInputChange }
                disabled={ isLoading }
                value={ formData.practice_id }
              >
                {
                  prestaciones.modules && Array.isArray(prestaciones.modules)
                    ? prestaciones.modules.map(item => <option key={ item.id } value={ item.id }>{ item.name }</option>)
                    : null
                }
              </Input>
            </Col>
            {
              Array.isArray(prestaciones.additionals) && prestaciones.additionals.length > 0
                ? <Col sm={6}>
                    <Label for="additional_id">Práctica adicional</Label>
                    <Input
                      id="additional_id"
                      name="additional_id"
                      type="select"
                      className="custom-select"
                      value={ formData.additional_id }
                      onChange={ handleInputChange }
                      disabled={ isLoading }
                    >
                      <option value=''>Ninguna</option>
                      {
                        prestaciones.additionals.map(item => (
                          <option key={ item.id } value={ item.id }>{ item.name }</option>
                        ))
                      }
                    </Input>
                  </Col>
                : null
            }
          </Row>
          <Row>
            <Col sm={ 12 } md={ 6 }>
              <Button
                color="primary"
                onClick={ handleVerification }
                disabled={ isLoading }
              >
                Validar
              </Button>
              <Button
                color="danger"
                onClick={ handleCancelation }
                disabled={ isLoading }
              >
                Cancelar
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <AlertModal isOpen={ alertShow } message={ alertMessage } onClose={ alertCallback } />
    </Container>
  );
}
