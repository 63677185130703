import React, { useEffect, useState, ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter } from 'reactstrap';
import { post } from 'services/ApiService';
import { useAlertPlus, useConfirm } from 'hooks';
import Attachments from 'components/Requests/Attachments';
import Required from 'components/Required';
import ConfirmModal from 'components/ConfirmModal';
import AlertModal from 'components/AlertModal';
import ObservationsAlert from 'components/Requests/ObservationAlert';
import { useUser } from 'context/UserContext';
import { useHistory } from 'react-router-dom';
import InputError from 'components/InputError';
import { getPractices } from 'features/practices/practicesAPI';
import { InsurerPractices, Request, RequestFiles, AttachmentType } from 'types/Request';
import DatePicker, { DatePickerChangeEvent } from 'components/DatePicker';
import { getRequest, updateRequest, createObservation } from 'features/requests/requestsAPI';
import ObservationList from 'components/Requests/ObservationList';

export default function Edit() {
  const { id } = useParams();

  const history = useHistory();

  const { getMatricula } = useUser();
  const matriculateId = getMatricula();
  if (!matriculateId) {
    return;
  }

  const insurersWithoutAuthorization = [
    55, // Medifé: sólo verificación afiliado
    60, // OSAP: sólo verificación afiliado
    61, // IOSFA: sólo verificación afiliado
    77, // OSPe: autorización por sesión
  ];

  const insurersRequiringReasonsToAnnul = [
    22, // COMEI
    60, // OSAP
  ];

  const needsAuth = (id: number) => !insurersWithoutAuthorization.includes(id);

  const [disableSend, setDisableSend] = useState(false);

  const [newSessionDisabled, setNewSessionDisabled] = useState(true);
  const [newSessionDate, setNewSessionDate] = useState<Date>();
  const [newSessionDateMin, setNewSessionDateMin] = useState<Date>();
  const [newSessionDateMax, setNewSessionDateMax] = useState<Date>();
  const [showSessionsModal, setShowSessionsModal] = useState(false);

  const [annulmentReason, setAnnulmentReason] = useState('');
  const [showAnnulmentModal, setShowAnnulmentModal] = useState(false);

  const [showAlert, message, alertCallback, alert] = useAlertPlus('');
  const [isLoading, setLoading] = useState(true);
  const [confirm, confirmTitle, confirmAction, setConfirm] = useConfirm(false);

  const [request, setRequest] = useState<Request>();

  const [prestaciones, setPrestaciones] = useState<InsurerPractices>({
    modules: [],
    additionals: [],
  });

  const [files, setFiles] = useState<RequestFiles>({});

  const [formValidation, setFormValidation] = useState<Partial<Record<keyof Request, boolean>>>({});

  const [prescriptionDateMin, setPrescriptionDateMin] = useState<Date>(new Date(Date.now() - 60 * 24 * 60 * 60 * 1000));
  const [prescriptionDateMax, setPrescriptionDateMax] = useState<Date>(new Date());

  const fetchPrestaciones = async (newRequest?: Request) => {
    try {
      if (!request && !newRequest) {
        return;
      }

      const currentRequest = newRequest ? newRequest : request!;

      const data = await getPractices({
        insurerId: currentRequest.insurer_id,
        matriculateId: parseInt(matriculateId),
        date: currentRequest.prescription_date_parsed ? currentRequest.prescription_date_parsed : undefined,
      });

      if (!data) {
        throw new Error('Hay problemas de conexión con el sistema de Facturación. ' +
          'Temporalmente no se podrá enviar la solicitud.');
      }

      setPrestaciones({
        modules: data.modules ?? [],
        additionals: data.additionals ?? [],
      });
    } catch (err) {
      alert(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);

    getRequest(id)
      .then(response => {
        if (!response) {
          return;
        }

        const { request, files: newFiles } = response;

        if (!request.id || !request.insurer_id) {
          alert('Se produjo un error al intentar recuperar la información de la presentación. ' +
            'Por favor, intente nuevamente más tarde', () => window.location.href = '/solicitudes');
          console.error('Edit-Sancor', `No se pudo recuperar información sobre la solicitud #${ id }`);
          console.info(request);
          return;
        }

        setFiles(newFiles);
        setRequest(request);
        fetchPrestaciones(request);
      })
      .catch(err => {
        setDisableSend(true);
        alert('Se produjo un error al intentar recuperar la información de la presentación. ' +
          'Por favor, intente nuevamente más tarde');
        console.error('Edit', 'Error inesperador al intentar recuperar la presentación');
        console.info(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    if (request?.rebilling) {
      setPrescriptionDateMin(new Date(new Date().getFullYear(), 0, 1));
      setPrescriptionDateMax(new Date(new Date().getFullYear(), 11, 31));
    } else if (request?.previously_authorized) {
      const min = new Date(Date.now() - 60 * 24 * 60 * 60 * 1000);
      const max = request?.authorization_date ?? new Date();

      // Se verifica que se reinicie la fecha al desactivar refacturación sólo si es inválida
      if (request?.prescription_date &&
        (request.prescription_date.valueOf() > max.valueOf() ||
        request.prescription_date.valueOf() < min.valueOf())) {
        setRequest({
          ...request,
          prescription_date: max,
        });
      }
      setPrescriptionDateMin(min);
      setPrescriptionDateMax(max);
    }
  }, [request?.authorization_date, request?.rebilling]);

  // Control del estado del botón de agregar sesiones
  useEffect(() => {
    if (!request) {
      return;
    }

    // Si la autorización es online y no hay fecha de autorización no se puede agregar sesiones
    if (!request.previously_authorized && !request.authorization_date) {
      setNewSessionDisabled(true);
      return;

    // Si la autorización es previa y no hay fecha de prescripción no se puede agregar sesiones
    } else if (request.previously_authorized && !request.prescription_date) {
      setNewSessionDisabled(true);
      return;

    // Si la autorización es online controlamos que no se exceda la cantidad de sesiones autorizadas
    } else if (!request.previously_authorized && request.sessions.length >= request.max_sessions) {
      setNewSessionDisabled(true);
      return;
    }

    setNewSessionDisabled(false);
  }, [
    request?.previously_authorized,
    request?.authorization_date,
    request?.prescription_date,
    request?.sessions,
    request?.max_sessions,
  ]);

  if (!request) {
    return <>Cargando...</>;
  }

  const handleBillingDraft = async () => {
    setFormValidation({});

    setLoading(true);

    updateRequest({
      ...request,
      status_id: 1,
    }, files, prestaciones)
      .then(res => {
        if (!res || !res.request.status_id || res.request.status_id !== 1) {
          console.error('EditSwiss', 'Error al enviar la solicitud');
          console.info(res);
          alert('Ha ocurrido un error al enviar la solicitud. Por favor intente nuevamente.');
          setLoading(false);
          return;
        }

        alert('La solicitud se envió correctamente', () => {
          history.push('/solicitudes');
        });
      })
      .catch(err => {
        console.error('EditSwiss', 'Error inesperado al intentar enviar la solicitud');
        console.info(err);
        alert('Ha ocurrido un error al enviar la solicitud. Por favor intente nuevamente.');
        setLoading(false);
      });
  };

  const handleFormValidation = () => {
    if (!request) {
      return;
    }

    let newFormValidation = {};

    let toValidate = [...document.getElementsByClassName('form-control')] as HTMLInputElement[];
    toValidate = toValidate.filter(e => !e.disabled && e.required);

    let missing = false;
    // Validar campos required
    toValidate.forEach(field => {
      if (field.required && !field.disabled && !field.value?.trim()) {
        field.classList.add('is-invalid');

        missing = true;
      } else if (field.inputMode === 'numeric') {
        if (!/^\d+$/.test(field.value)) {
          newFormValidation = {
            ...newFormValidation,
            [field.name]: false,
          };
        }
      } else {
        field.classList.remove('is-invalid');
      }
    });

    // Validar file inputs required
    toValidate = [...document.getElementsByClassName('custom-file-input')] as HTMLInputElement[];
    toValidate.forEach(field => {
      if (field.required && !field.disabled && !files[field.dataset.key as AttachmentType]) {
        field.classList.add('is-invalid');

        missing = true;
      }
    });

    if (
      request.insurer_id === 36 &&
      request.previously_authorized === true &&
      !request.impairment &&
      !request.rebilling && !request.exceptional
    ) {
      newFormValidation = {
        ...newFormValidation,
        impairment: false,
        rebilling: false,
        exceptional: false,
      };

      missing = true;
    }

    // Validar cantidad de sesiones
    if (request.sessions.length === 0) {
      newFormValidation = {
        ...newFormValidation,
        sessions: false,
      };

      missing = true;
    }

    if (missing) {
      setFormValidation(newFormValidation);
      alert('Todos los campos marcados con * son obligatorios. ' +
        'Por favor complete los faltantes antes de enviar la solicitud.');
      return false;
    }

    // Validar cantidad de sesiones
    if (!request.previously_authorized && request.sessions.length > request.max_sessions) {
      alert(`Únicamente se pueden cargar hasta ${ request.max_sessions } sesiones.`);
      return false;
    }

    return true;
  };

  // Confirmación de anulación de autorización
  const handleBillingAnnulmentConfirmation = () => {
    if (!request) {
      return;
    }

    // Algunas aseguradoras dan la opción de agregar un moitivo por el cual se solicita
    // la anulación
    if (insurersRequiringReasonsToAnnul.includes(request.insurer_id)) {
      setAnnulmentReason('');
      setShowAnnulmentModal(true);
      return;
    }

    setConfirm(true, '¿Estás seguro que deseas anular la autorización?', 'annulment');
  };

  // Confirmación de envío de Solicitud
  const handleBillingConfirmation = () => {
    if (!handleFormValidation()) {
      return;
    }

    setConfirm(true, '¿Estás seguro que deseas enviar esta solicitud?', 'billing');
  };

  // Gestor de respuestas de confirmaciones
  const handleConfirmation = (action: string) => {
    if (action === 'billing') {
      handleBillingRequest();
    } else if (action === 'annulment') {
      handleBillingAnnulment();
    }

    setConfirm(false);
  };

  // Envío de solicitudes
  const handleBillingRequest = () => {
    setFormValidation({});

    setLoading(true);

    updateRequest({
      ...request,
      status_id: 2,
    }, files, prestaciones)
      .then(res => {
        if (!res || !res.request.status_id) { // Error interno
          console.error('Edit', 'Error al enviar la solicitud');
          console.info(res);
          alert(`Ha ocurrido un error al enviar la solicitud. Por favor intente nuevamente.`);
          return;
        } else if (res.request.status_id === 10) { // Fuera de término
          alert('La solicitud fue rechazada porque se encuentra fuera de término', () => {
            history.push('/solicitudes');
          });
        } else if (res.request.status_id !== 2 && res.request.status_id !== 3) { // Otros errores
          console.error('Edit', 'Error al enviar la solicitud');
          console.info(res);
          alert('Ha ocurrido un error al procesar la solicitud enviada. Por favor intente nuevamente.');
          return;
        }

        alert('La solicitud se envió correctamente', () => {
          history.push('/solicitudes');
        });
      })
      .catch(err => {
        console.error('Edit', 'Error inesperado al intentar enviar la solicitud');
        console.info(err);
        alert('Ha ocurrido un error inesperado al enviar la solicitud. Por favor intente nuevamente.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Anulación de autorizaciones
  const handleBillingAnnulment = async () => {
    try {
      setLoading(true);

      const response = await post('insurers/annul', {
        id,
        insurer_id: request.insurer_id,
        recipient_id: request.recipient_id,
        authorization_id: request.authorization_id,
        reason: annulmentReason,
      });

      // Errores
      if (response.status === 'error') {
        if (response.message) {
          console.error('Edit', 'Error al intentar anular la autorización online');
          console.info(response);
          alert(response.message);
        } else {
          console.error('Edit', 'Error desconocido al intentar anular la autorización online');
          console.info(response);
          alert('Hubo un problema al intentar anular la autorización de práctica. ' +
            'Por favor, intente nuevamente más tarde');
        }

        setLoading(false);
        return;
      }

      alert(`La autorización fue anulada por la Obra Social`, () => {
        history.replace(`/solicitudes`);
      });
    } catch (err) {
      console.error('Edit', 'Error imprevisto al intentar anular la autorización online');
      console.info(err);
      setLoading(false);
      alert('Ha ocurrido un error al anular la solicitud. Por favor intente nuevamente.');
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement> | DatePickerChangeEvent) => {
    const { target } = event;

    const name = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const extra: Partial<Request> = {};

    // Si cambió el número de afiliado, borramos el nombre
    if (name === 'recipient_id') {
      extra['recipient_name'] = undefined;
    } else if (name === 'recipient_age' && typeof value === 'string') {
      value = value.replace(/\D/g, '');
    }

    // Guardamos el nuevo valor
    setRequest({
      ...request,
      ...extra,
      [name]: value,
    } as Request);
  };

  const handleNewSession = () => {
    if (!newSessionDate) {
      return;
    }

    if (request.sessions.find(s => s.date === newSessionDate)) {
      alert('No se puede agregar más de una sesión por día');
      return;
    }

    const newSessions = [
      ...request.sessions,
      {
        date: newSessionDate,
        date_parsed: newSessionDate.toISOString().split('T')[0],
        authorization_id: '',
        coinsurance: '0.0',
        online: !request.previously_authorized,
      },
    ].sort((a, b) => (a.date?.getTime() ?? 0) - (b.date?.getTime() ?? 0));

    // Agregamos al arreglo de fechas de sesiones la nueva fecha
    setRequest({
      ...request,
      sessions: newSessions,
    });

    // Ocultamos el modal
    setShowSessionsModal(false);
  };

  const handleRemoveSession = (target: number) => {
    // Filtramos del arreglo de sesiones el índice correspondiente
    setRequest({
      ...request,
      sessions: request.sessions.filter((value, idx) => idx !== target),
    } as Request);
  };

  const showNewSessionModal = async () => {
    // Variables de fecha auxiliares
    const today = new Date();
    const fiveDaysAgo = new Date();
    fiveDaysAgo.setDate(today.getDate() - 5);

    // Límite inferior para el rango de selección de fechas
    let sessionDateMin;
    // Límite superior para el rango de selección de fechas
    let sessionDateMax;

    // Caso donde es una refacturación y la fecha de prescripción médica es del año corriente y dentro del rango de un año en adelante
    if (request.rebilling) {
      if (!request.prescription_date) {
        alert('Tiene que seleccionar la fecha de prescripción médica para poder cargar sesiones');
        return;
      }

      sessionDateMax = new Date(request.prescription_date.valueOf());
      sessionDateMax.setFullYear(sessionDateMax.getFullYear() + 1);

      // Seteamos todos los parámetros
      setNewSessionDateMin(request.prescription_date);
      setNewSessionDateMax(sessionDateMax);
      setNewSessionDate(today);

      // Mostramos el modal
      setShowSessionsModal(true);

      return;
    }

    // Si tenemos fecha de prescripción utilizamos esta para calcular los límites de las sesiones
    if (request.prescription_date) {
      sessionDateMin = new Date(request.prescription_date.valueOf());

      sessionDateMax = new Date(request.prescription_date.valueOf());
      sessionDateMax.setDate(request.prescription_date.getDate() + 60);

    // Si sólo tenemos la fecha de autorización la usamos en cambio
    } else if (request.authorization_date) {
      sessionDateMin = new Date(request.authorization_date.valueOf());

      sessionDateMax = new Date(request.authorization_date.valueOf());
      sessionDateMax.setDate(request.authorization_date.getDate() + 60);
    }

    // Si el tiempo mínimo calculado es menor a cinco días de la fecha actual utilizamos cinco días para atrás
    if (!sessionDateMin || sessionDateMin.valueOf() < fiveDaysAgo.valueOf()) {
      sessionDateMin = fiveDaysAgo;
    }

    // Únicamente para Avalian se modifica la fecha minima para solicitud de discapacidad
    if (request.previously_authorized && request.insurer_id == 36 && request.impairment) {
      sessionDateMin = new Date(today.valueOf());
      sessionDateMin.setDate(sessionDateMin.getDate() - 120);
    }

    // Si el tiempo máximo calculado supera la fecha actual utilizamos la fecha del día
    if (!sessionDateMax || new Date().valueOf() < sessionDateMax.valueOf()) {
      sessionDateMax = today;
    }

    // Fecha sugerida
    let sessionDate: Date | undefined = undefined;

    // Si no hay fecha de sesión definida (no se cargó ninguna), tomamos la fecha
    // mínima, si no tomamos el día siguiente a la última fecha
    if (!request.sessions.length) {
      sessionDate = sessionDateMin;
    } else {
      const lastSessionDate = request.sessions.map(({ date }) => date).sort((a, b) => b.getTime() - a.getTime())[0];
      const tentativeSessionDate = new Date(lastSessionDate.valueOf());
      tentativeSessionDate.setDate(lastSessionDate.getDate() + 1);

      // Sólo tomamos la fecha tentativa si en menor a hoy
      if (tentativeSessionDate.valueOf() < today.valueOf()) {
        sessionDate = tentativeSessionDate;
      }
    }

    // Seteamos todos los parámetros
    setNewSessionDateMin(sessionDateMin);
    setNewSessionDateMax(sessionDateMax);
    setNewSessionDate(sessionDate);

    // Mostramos el modal
    setShowSessionsModal(true);
  };

  const handleNewObservation = async (obserbation: string) => {
    setLoading(true);

    const result = await createObservation(request, obserbation);

    if (result.status === 'ok') {
      setRequest({
        ...request,
        observations: result.request.observations,
      });
    }

    setLoading(false);

    return result.status === 'ok';
  };

  return (
    <div className="content">
      <Row>
        <Col sm={12}>
          <Card>
            <CardHeader>
              <Row style={{ alignItems: 'center' }}>
                <Col sm={8}>
                  <CardTitle tag="h5">
                    Presentación #{ id }{ request.insurer_shortname ? ' - ' + request.insurer_shortname : '' }
                  </CardTitle>
                </Col>
                <Col sm={4} className="text-right">
                  <Button className="btn-round" color="primary" onClick={ () => history.push('/solicitudes') }>
                    Volver
                  </Button>
                </Col>
              </Row>
              <ObservationsAlert observations={ request.observations } />
            </CardHeader>
            <CardBody>
              <Form>
                <FormGroup className={ needsAuth(request.insurer_id) ? 'd-block' : 'd-none' }>
                  <Row form>
                    <Col sm={6}>
                      <Label for="authorization_id">Número de Aprobación <Required /></Label>
                      <Input
                        id="authorization_id"
                        name="authorization_id"
                        value={ request.authorization_id ?? '' }
                        invalid={ formValidation.authorization_id === false }
                        onChange={ handleInputChange }
                        disabled={ isLoading || !request.previously_authorized || !needsAuth(request.insurer_id) }
                        required
                      />
                    </Col>
                    <Col sm={6}>
                      <Label for="authorization_date">Fecha de Aprobación</Label>
                      <DatePicker
                        id="authorization_date"
                        name="authorization_date"
                        selected={ request.authorization_date ?? '' }
                        dateFormat="dd/MM/yyyy"
                        locale="es"
                        placeholderText="Fecha de Aprobación"
                        className="date-picker form-control"
                        maxDate={ new Date() }
                        disabled={ isLoading || !request.previously_authorized }
                        onChange={ date => {
                          handleInputChange({ target: {
                            name: 'authorization_date',
                            value: date,
                            type: 'datepicker',
                          } });
                        }}
                        autoComplete="off"
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row form>
                    <Col sm={6}>
                      <Label for="recipient_id">Número de Afiliado <Required /></Label>
                      <Input
                        id="recipient_id"
                        name="recipient_id"
                        value={ request.recipient_id ?? '' }
                        invalid={ formValidation.recipient_id === false }
                        onChange={ handleInputChange }
                        disabled={ isLoading || !request.previously_authorized || request.recipient_validated }
                        required
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Label for="recipient_name">Nombre del Afiliado <Required /></Label>
                  <Input
                    id="recipient_name"
                    name="recipient_name"
                    value={ request.recipient_name ?? '' }
                    invalid={ formValidation.recipient_name === false }
                    onChange={ handleInputChange }
                    disabled={
                      isLoading ||
                      (!request.previously_authorized && request.recipient_name !== '') ||
                      request.recipient_validated
                    }
                    required
                  />
                </FormGroup>
                <FormGroup className="d-block">
                  <Label for="recipient_age">Edad (en años)<Required /></Label>
                  <Input
                    id="recipient_age"
                    name="recipient_age"
                    type="text"
                    inputMode="numeric"
                    pattern="\d*"
                    value={ request.recipient_age ?? '' }
                    invalid={ formValidation.recipient_age === false }
                    onChange={ handleInputChange }
                    disabled={
                      isLoading ||
                      (!request.previously_authorized && request.recipient_age !== 0) ||
                      (request.recipient_validated && request.recipient_age !== 0) }
                    required
                  />
                  {
                    formValidation.recipient_age === false
                      ? <InputError message="Formato incorrecto. Por favor ingrese únicamente números" />
                      : null
                  }
                </FormGroup>
                <FormGroup>
                  <Row form>
                    <Col sm={6}>
                      <Label for="prescription_date">Fecha Prescripción Médica <Required /></Label>
                      <DatePicker
                        id="prescription_date"
                        name="prescription_date"
                        selected={ request.prescription_date ?? '' }
                        dateFormat="dd/MM/yyyy"
                        locale="es"
                        placeholderText="Fecha Prescripción"
                        className="date-picker form-control"
                        minDate={ prescriptionDateMin }
                        maxDate={ prescriptionDateMax }
                        disabled={ isLoading || !request.previously_authorized }
                        onChange={ date => {
                          handleInputChange({ target: {
                            name: 'prescription_date',
                            type: 'datepicker',
                            value: date,
                          } });
                        } }
                        autoComplete="off"
                        required
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Label for="diagnosis">Diagnóstico <Required /></Label>
                  <Input
                    id="diagnosis"
                    name="diagnosis"
                    type="textarea"
                    invalid={ formValidation.diagnosis === false }
                    value={ request.diagnosis ?? '' }
                    onChange={ handleInputChange }
                    disabled={ isLoading }
                    required
                  />
                </FormGroup>
                {
                  formValidation.impairment === false ||
                  formValidation.rebilling === false ||
                  formValidation.exceptional === false
                    ? <InputError message="Tiene que seleccionar una de las tres opciones a continuación:" />
                    : null
                }
                <FormGroup
                  style={{ paddingLeft: '1.5rem' }}
                  className={ request.previously_authorized && request.insurer_id === 36 ? 'd-block' : 'd-none' }
                >
                  <Input
                    id="impairment"
                    name="impairment"
                    type="checkbox"
                    checked={ request.impairment }
                    invalid={ formValidation.impairment === false }
                    onChange={ handleInputChange }
                    disabled={ isLoading || request.rebilling || request.exceptional }
                  />
                  <Label for="impairment" style={{ marginBottom: 0, verticalAlign: 'bottom' }}>
                    Es discapacidad
                  </Label>
                </FormGroup>
                <FormGroup
                  style={{ paddingLeft: '1.5rem' }}
                  className={ request.previously_authorized && request.insurer_id === 36 ? 'd-block' : 'd-none' }
                >
                  <Input
                    id="rebilling"
                    name="rebilling"
                    type="checkbox"
                    checked={ request.rebilling }
                    invalid={ formValidation.rebilling === false }
                    onChange={ handleInputChange }
                    disabled={ isLoading || request.impairment || request.exceptional }
                  />
                  <Label for="rebilling" style={{ marginBottom: 0, verticalAlign: 'bottom' }}>
                    Es refacturación
                  </Label>
                </FormGroup>
                <FormGroup
                  style={{ paddingLeft: '1.5rem' }}
                  className={ request.previously_authorized && request.insurer_id === 36 ? 'd-block' : 'd-none' }
                >
                  <Input
                    id="exceptional"
                    name="exceptional"
                    type="checkbox"
                    checked={ request.exceptional }
                    invalid={ formValidation.exceptional === false }
                    onChange={ handleInputChange }
                    disabled={ isLoading || request.impairment || request.rebilling }
                  />
                  <Label for="exceptional" style={{ marginBottom: 0, verticalAlign: 'bottom' }}>
                    Es excepcionalidad
                  </Label>
                </FormGroup>
                <FormGroup row className={ request.exceptional ? 'd-block' : 'd-none' }>
                  <Col>
                    <Input
                      id="exceptional_text"
                      name="exceptional_text"
                      type="textarea"
                      value={ request.exceptional_text ?? '' }
                      invalid={ formValidation.exceptional_text === false }
                      onChange={ handleInputChange }
                      disabled={ isLoading || !request.exceptional }
                      required
                    />
                  </Col>
                </FormGroup>
                <FormGroup className={ !request.previously_authorized ? 'd-block' : 'd-none' }>
                  <Row form>
                    <Col sm={6}>
                      <Label>Cantidad de Sesiones</Label>
                      <Input
                        id="max_sessions"
                        name="max_sessions"
                        type="number"
                        min="1"
                        disabled={ isLoading || !request.previously_authorized }
                        value={ request.max_sessions }
                        onChange={ handleInputChange }
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Label>Fechas de sesiones{
                    !request.previously_authorized
                      ? `: ${ request.max_sessions } autorizadas, ${ request.sessions.length } cargadas`
                      : ''
                  }</Label>
                  <Button
                    className="d-block"
                    color="primary"
                    onClick={ showNewSessionModal }
                    disabled={ isLoading || newSessionDisabled }
                  >
                    Agregar sesión
                  </Button>
                  <small className={`mt-2 text-muted ${ request.authorization_date ? 'd-none' : 'd-block' }`}>
                    Se tiene que seleccionar la fecha de autorización antes de poder agregar las sesiones
                  </small>
                  <Table
                    className='align-items-center table-flush mb-0 col-sm-6'
                    style={{ fontSize: '.8rem' }}
                    responsive={ false }
                  >
                    <thead>
                      <tr>
                        <th>FECHA SESIÓN</th>
                        <th>ACCIONES</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        request.sessions && Array.isArray(request.sessions)
                          ? request.sessions
                            .sort((a, b) => a.date.getTime() - b.date.getTime())
                            .map(({ date }, i) => (
                              <tr key={ i }>
                                <td>
                                  { date.getDate() }/
                                  { (date.getMonth() + 1).toString().padStart(2, '0') }/
                                  { date.getFullYear() }
                                </td>
                                <td className='text-left table__mobile--body__actions'>
                                  <Button color="link" onClick={ () => handleRemoveSession(i) } disabled={ isLoading } >
                                    <i className="fa fa-times" style={{ color: 'red' }}></i>
                                  </Button>
                                </td>
                              </tr>
                            ))
                          : null
                      }
                    </tbody>
                  </Table>
                  {
                    request.sessions.length === 0
                      ? <InputError message="Tiene que cargar al menos una sesión" />
                      : null
                  }
                </FormGroup>
                <FormGroup row>
                  <Col sm={6}>
                    <Label for="practice_id">Tipo de práctica <Required /></Label>
                    <Input
                      id="practice_id"
                      name="practice_id"
                      type="select"
                      className="custom-select"
                      value={ request.practice_id ?? '' }
                      invalid={ formValidation.practice_id === false }
                      onChange={ handleInputChange }
                      disabled={ isLoading || !request.previously_authorized }
                      required
                    >
                      <option disabled value='' hidden>Seleccione una opción...</option>
                      {
                        Array.isArray(prestaciones.modules) && prestaciones.modules.map(item => {
                          return <option key={ item.id } value={ item.id }>{ item.name }</option>;
                        })
                      }
                    </Input>
                  </Col>
                  {
                    Array.isArray(prestaciones.additionals) && prestaciones.additionals.length > 0
                      ? <Col sm={6}>
                          <Label for="additional_id">Práctica adicional</Label>
                          <Input
                            id="additional_id"
                            name="additional_id"
                            type="select"
                            className="custom-select"
                            value={ request.additional_id ?? '' }
                            invalid={ formValidation.additional_id === false }
                            onChange={ handleInputChange }
                            disabled={ isLoading || !request.previously_authorized }
                          >
                            <option value=''>Ninguna</option>
                            {
                              prestaciones.additionals.map(item => {
                                return <option key={ item.id } value={ item.id }>{ item.name }</option>;
                              })
                            }
                          </Input>
                        </Col>
                      : null
                  }
                </FormGroup>
                <Attachments
                  insurer_id={ request.insurer_id }
                  files={ files }
                  sessions={ request.sessions.length }
                  previously_authorized={ request.previously_authorized }
                  setFiles={ setFiles }
                  disabled={ isLoading }
                  impairment={ request.impairment }
                  rebilling={ request.rebilling }
                  exceptional={ request.exceptional }
                  setLoading={ setLoading }
                />
                <FormGroup>
                  <Label>Observaciones</Label>
                  <ObservationList
                    observations={ request.observations }
                    disabled={ isLoading }
                    createObservation={ handleNewObservation }
                  />
                </FormGroup>
                <FormGroup>
                  <div className="text-center">
                    <Button
                      type="button"
                      color="danger"
                      className={ `btn-round mr-4 ${ request.previously_authorized ? 'd-none' : '' }` }
                      onClick={ handleBillingAnnulmentConfirmation }
                      disabled={ isLoading }
                    >
                      Anular Autorización
                    </Button>
                    <Button
                      type="button"
                      color="primary"
                      className="btn-round mr-4"
                      onClick={ handleBillingDraft }
                      disabled={ isLoading }
                    >
                      Guardar Borrador
                    </Button>
                    <Button
                      type="button"
                      className="btn-round"
                      onClick={ handleBillingConfirmation }
                      disabled={ isLoading || disableSend }
                    >
                      Enviar Solicitud
                    </Button>
                  </div>
                </FormGroup>
              </Form>
              <AlertModal isOpen={ showAlert } message={ message } onClose={ alertCallback } />
              <Modal isOpen={ showSessionsModal } size="md" centered>
                <ModalHeader>Agregar fecha de sesión</ModalHeader>
                <ModalBody>
                  <FormGroup className='card form-group-dates'>
                    <Label>Fecha</Label>
                    <DatePicker
                      id="session_date"
                      name="session_date"
                      onChange={ date => setNewSessionDate(date ? date : undefined) }
                      dateFormat="dd/MM/yyyy"
                      locale="es"
                      placeholderText="Fecha de la sesión"
                      minDate={ newSessionDateMin ?? '' }
                      maxDate={ newSessionDateMax ?? '' }
                      excludeDates={ request.sessions.map(({ date }) => date) }
                      selected={ newSessionDate }
                      className="form-control"
                      autoComplete="off"
                    />
                  </FormGroup>
                </ModalBody>
                <ModalFooter>
                  <Button color="success" onClick={ () => handleNewSession() }>
                    Agregar
                  </Button>
                  <Button color="primary" onClick={ () => setShowSessionsModal(false) }>
                    Cancelar
                  </Button>
                </ModalFooter>
              </Modal>
              <ConfirmModal
                isOpen={ confirm }
                title={ confirmTitle }
                action={ confirmAction }
                onClose={ () => setConfirm(false) }
                onConfirm={ handleConfirmation }
              />
              <Modal isOpen={ showAnnulmentModal } size="md" centered>
                <ModalHeader>Anular Autorización</ModalHeader>
                <ModalBody>
                  <FormGroup>
                    <Label>Motivo de la Solicitud de Anulación</Label>
                    <Input
                      id="annulment_reason"
                      name="annulment_reason"
                      type="textarea"
                      onChange={ ({ target }) => setAnnulmentReason(target.value) }
                      className="form-control"
                      required
                    />
                  </FormGroup>
                </ModalBody>
                <ModalFooter>
                  <Button color="success" onClick={ () => handleBillingAnnulment() }>
                    Anular
                  </Button>
                  <Button color="primary" onClick={ () => setShowAnnulmentModal(false) }>
                    Cancelar
                  </Button>
                </ModalFooter>
              </Modal>
            </CardBody>
            <CardFooter>
              <Row style={{ alignItems: 'center' }}>
                <Col sm={8}></Col>
                <Col sm={4} className="text-right">
                  <Button className="btn-round" color="primary" onClick={ () => history.push('/solicitudes') }>
                    Volver
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
