export const attachs = {
  // derivacion_medica: 'Derivación médica',
  frente_orden: 'Frente prescripción médica',
  dorso_orden: 'Dorso prescripción médica',
  bono: 'Bono',
  ...getMultiplesBonos(),
  factura: 'Factura electrónica',
  historia_clinica: 'Historia clínica',
  certificado_discapacidad: 'Certificado de discapacidad',
  planilla_asistencia: 'Planilla asistencia',
  nota_debito: 'Nota débito/crédito',
  planilla_limitacion: 'Planilla limitación funcional',
  autorizacion: 'Autorización OOSS',
  exceptional: 'Documentación excepcionalidad',
  constancia_deportiva: 'Carnet o constancia expedida por la federación deportiva'
}

// para cuando hay mas de 1 bono
function getMultiplesBonos (q = 100) {
  let bonos = {}
  for (let i = 1; i <= q; i++) {
    bonos = { ...bonos, [`bono_${i}`]: `Bono sesión ${i}` }
  }
  return bonos
}