import React, { useState, Fragment } from "react"
import { Link } from "react-router-dom"
import { Table, Card } from "reactstrap"
import Loader from "components/TableLoader"
import status from 'services/status'

export default function(props) {

  const { items, isLoading, currentOrder, changeOrder, onCancel } = props

  const [activeRow, setActiveRow] = useState(-1)

  const getEstado = id => {
    // Para los matriculados todo estado después de la auditoría
    // se muestra como auditada
    if ([6, 7, 8].includes(id)) return 'Auditada'

    // La solicitudes sin status se consideran borradores (status = 1)
    return status[id] || 'Borrador'
  }

  const getTogglerClass = (id) => {
    if (activeRow === id) {
      return 'fa fa-caret-down'
    }
    return 'fa fa-caret-right'
  }

  const getIconClassBySorted = (field) => {
    if (currentOrder.field === field) {
      if (currentOrder.dir === -1) {
        return 'fa fa-caret-down'
      } else {
        return 'fa fa-caret-up'
      }
    }

    return 'fas fa-sort'
  }

  const setNewOrder = (field) => {
    // Si el campo elegido ya está seleccionado, sólo cambiamos el orden
    if (currentOrder && currentOrder.field === field) {
      changeOrder(
        currentOrder.field,
        currentOrder.dir === 1 ? -1 : 1
      )
    // Si es un nuevo campo, lo indicamos como seleccionado y reseteamos el orden
    // a ascendente
    } else {
      changeOrder(field, 1)
    }
  }

  const cancelRequest = (id) => {
    return onCancel(id)
  }

  const toggleExpand = (index) => {
    if (activeRow === index) {
      return setActiveRow(-1)
    }
    setActiveRow(index)
  }

  const columns = [
    {
      label: 'N°',
      name: 'id',
      sort: true,
      value: (row) => `#${ row.id }`
    },
    {
      label: 'Obra social',
      name: 'ooss',
      sort: true,
      value: (row) => row.ooss ? row.ooss.codigo : ''
    },
    {
      label: 'N° aprobación',
      name: 'numero_aprobacion',
      sort: true,
      value: (row) => row.numero_aprobacion || ''
    },
    {
      label: 'Beneficiario',
      name: 'afiliado',
      sort: true,
      value: (row) => row.affiliate ? row.affiliate.nombre_apellido : ''
    },
    {
      label: 'Prescripción',
      name: 'fecha_prescripcion_medica',
      sort: true,
      value: (row) => row.fecha_prescripcion_medica?.split('-').reverse().join('/') ?? ' - '
    },
    {
      label: 'Mes Prestación',
      name: 'mes_prestacion',
      sort: false,
      value: (row) => {
        if (!row.sessionDates || !Array.isArray(row.sessionDates) || row.sessionDates.length === 0) {
          return ' - ';
        }

        const practicesMonths = row.sessionDates
          .map(s => s.mesPrestacion) // Extraemos las fechas de las prestaciones
          .sort((a, b) => { // Ordenamos las fechas
            a = a.split('-').join('')
            b = b.split('-').join('')

            return a > b ? 1 : a < b ? -1 : 0
          })

        return practicesMonths[practicesMonths.length - 1]
          .split('-') // Dividimos las fechas por los guiones: '2022-04-01' => ['2022', '04', '01']
          .slice(0, -1) // Borramos el día: ['2022', '04', '01'] => ['2022', '04']
          .reverse() // Invertimos el array ['2022', '04'] => ['04', '2022']
          .join('/') // Lo unimos con barras ['04', '2022'] => '04/2022'
      },
    },
    {
      label: 'Estado',
      name: 'estado_id',
      sort: true,
      value: (row) => row.status ? getEstado(row.estado_id) : 'Borrador'
    },
    {
      label: 'Acciones',
      name: 'actions',
      value: (row) => {
        const editable = row.estado_id === 1 || row.estado_id === 4 // Solicitud borrador u observada
        const sessions = row.ooss?.id === 57 || row.ooss?.id === 66 || row.ooss?.id === 77 // Autorización por sesión no por solicitud
        const offline = row.aprobacion_previa // Autorización realizada directamente la OOSS

        let url = `/solicitudes/${row.id}`

        if (editable) {
          switch(row.ooss.id) {
            case 57: url += '/edit-swiss'; break
            case 66:
              if (row.aprobacion_previa) {
                url += '/edit-sancor-4';
              } else {
                url += '/edit-sancor';
              }
              break
            case 74: url += '/edit-federada';  break
            case 77: url += '/edit-sessions';  break
            default: url += '/edit'
          }
        }
        
        return (<>
          <Link to={ url }><i className="fa fa-eye show-button"></i></Link>
          {
            (editable && !sessions && offline) || (editable && sessions && row.sessionDates.length === 0)
            ? <div className='delete-request clickable ml-2' onClick={ () => cancelRequest(row.id) }>
                <i className="fa fa-times" style={{ color: 'red' }}></i>
              </div>
            : null
          }
        </>
        )
      }
    }
  ]

  const mobileColumns = [
    {
      label: '',
      name: 'toggler'
    },
    {
      label: 'N°',
      name: 'id',
      sort: true,
      value: (row) => `#${ row.id }`
    },
    {
      label: 'Estado',
      name: 'estado_id',
      sort: true,
      value: (row) => row.status ? getEstado(row.estado_id) : 'Borrador'
    },
    {
      label: 'Acciones',
      name: 'actions',
      value: columns[columns.length - 1].value // Same escritorio
    }
  ]

  return (
    <>
      <Table className='align-items-center table-flush table--desktop' responsive={ false }>
        <thead className="thead-primary table--head">
          <tr className='table--head__tr'>
            {
              // Cabecera
              columns.map((column) => {
                if (!column.sort) {
                  return <th key={column.name} scope='col' className={column.classes ?? ''}>{ column.label }</th>
                }
  
                return (
                  <th key={column.name} scope='col' className={ `clickable ${column.classes ?? ''}` } onClick={ () => setNewOrder(column.name) }>
                    { column.label }&nbsp;
                    { <i className={ getIconClassBySorted(column.name) }></i> }
                  </th>
                )
              })
            }
          </tr>
        </thead>
        <tbody className='table--body'>
        {
          // Datos
          !isLoading
          ? Array.isArray(items) && items.length
            ? items.map(row =>
              <tr key={row.id}>
                { columns.map(column => <td key={ column.name } className={ column.classes ?? '' } >{ column.value(row) }</td>) }
              </tr>
            )
            : <tr>
                <td colSpan={ columns.length } className='text-center'>
                  <span>No se encontraron solicitudes</span>
                </td>
              </tr>
          : <tr>
              <td colSpan={ columns.length } className='text-center'>
                <Loader />
              </td>
            </tr>
        }
        </tbody>
      </Table>
      {/* MOBILE */}
      <Table className='align-items-center d-sm-none table-borderless' responsive={false}>
        <thead className="thead-primary table__mobile--head">
          <tr className='table__mobile--head__tr'>
            {
              mobileColumns.map((column) => {
                if (!column.sort) {
                  return <th key={column.name} scope='col' className={column.classes ?? ''}>{ column.label }</th>
                }

                return (
                  <th key={column.name} scope='col' className={ `clickable ${column.classes ?? ''}` } onClick={ () => setNewOrder(column.name) }>
                    { column.label }&nbsp;
                    { <i className={ getIconClassBySorted(column.name) }></i> }
                  </th>
                )
              })
            }
          </tr>
        </thead>
        <tbody className='table__mobile--body'>
          {
            // Datos
            !isLoading
            ? Array.isArray(items) && items.length
              ? items.map(row =>
                <Fragment key={row.id}>
                  <tr key={row.id}>
                    {
                      mobileColumns.map(column => {
                        if (column.name === 'toggler') {
                          return (
                            <td key={ column.name } className={ column.classes ?? '' } onClick={ () => toggleExpand(row.id) }>
                              <i className={`table__mobile--toggler ${ getTogglerClass(row.id) } pr-3`} />
                            </td>
                          )
                        }

                        return <td key={ column.name } className={ column.classes ?? '' }>{ column.value(row) }</td>
                      })
                    }
                  </tr>
                  <tr className={ activeRow === row.id ? '' : 'hidden' }>
                    <td colSpan={ mobileColumns.length }>
                      <Card>
                        <Table>
                          <tbody>
                            <tr>
                              <td><strong>OBRA SOCIAL</strong></td>
                              <td>{ row.ooss ? row.ooss.codigo : '-' }</td>
                            </tr>
                            <tr>
                              <td><strong>N° APROBACIÓN</strong></td>
                              <td>{ row.numero_aprobacion || '-' }</td>
                            </tr>
                            <tr>
                              <td><strong>BENEFICIARIO</strong></td>
                              <td>{ row.affiliate?.nombre_apellido || '-' }</td>
                            </tr>
                            <tr>
                              <td><strong>ACTUALIZADO</strong></td>
                              <td>{ new Date(row.updatedAt).toLocaleString('es') }</td>
                            </tr>
                          </tbody>
                        </Table>
                      </Card>
                    </td>
                  </tr>
                </Fragment>
              )
              : <tr>
                  <td colSpan={ mobileColumns.length } className='text-center'>
                    <span>No se encontraron solicitudes</span>
                  </td>
                </tr>
            : <tr>
                <td colSpan={ mobileColumns.length } className='text-center'>
                  <Loader />
                </td>
              </tr>
          }
        </tbody>
      </Table>
    </>
  )
}
