import React, { useEffect, useState, ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter } from 'reactstrap';
import { post } from 'services/ApiService';
import { useAlertPlus, useConfirm } from 'hooks';
import Attachments from 'components/Requests/Attachments';
import Required from 'components/Required';
import ConfirmModal from 'components/ConfirmModal';
import AlertModal from 'components/AlertModal';
import ObservationsAlert from 'components/Requests/ObservationAlert';
import { useUser } from 'context/UserContext';
import { useHistory } from 'react-router-dom';
import InputError from 'components/InputError';
import { getPractices } from 'features/practices/practicesAPI';
import { InsurerPractices, Request, RequestFiles, AttachmentType } from 'types/Request';
import DatePicker, { DatePickerChangeEvent } from 'components/DatePicker';
import { getRequest, updateRequest, createObservation } from 'features/requests/requestsAPI';
import ObservationList from 'components/Requests/ObservationList';
import { useStore } from 'context/StoreContext';
import FakeInput from 'components/FakeInput';

export default function Edit() {
  const { id } = useParams();

  const history = useHistory();

  const { updateSubmission } = useStore();

  const { getMatricula } = useUser();
  const matriculateId = getMatricula();
  if (!matriculateId) {
    return;
  }

  const insurersRequiringReasonsToAnnul = [
    22, // COMEI
    60, // OSAP
  ];

  const [disableSend, setDisableSend] = useState(false);

  const [newSessionDisabled, setNewSessionDisabled] = useState(true);
  const [newSessionDate, setNewSessionDate] = useState<Date>();
  const [newSessionDateMin, setNewSessionDateMin] = useState<Date>();
  const [newSessionDateMax, setNewSessionDateMax] = useState<Date>();
  const [showSessionsModal, setShowSessionsModal] = useState(false);

  const [annulmentReason, setAnnulmentReason] = useState('');
  const [showAnnulmentModal, setShowAnnulmentModal] = useState(false);

  const [showAlert, message, alertCallback, alert] = useAlertPlus('');
  const [isLoading, setLoading] = useState(true);
  const [confirm, confirmTitle, confirmAction, setConfirm] = useConfirm(false);

  const [request, setRequest] = useState<Request>();

  const [prestaciones, setPrestaciones] = useState<InsurerPractices>({
    modules: [],
    additionals: [],
  });

  const [files, setFiles] = useState<RequestFiles>({});

  const [formValidation, setFormValidation] = useState<Partial<Record<keyof Request, boolean>>>({});

  const fetchPrestaciones = async (newRequest?: Request) => {
    try {
      if (!request && !newRequest) {
        return;
      }

      const currentRequest = newRequest ? newRequest : request!;

      const data = await getPractices({
        insurerId: currentRequest.insurer_id,
        matriculateId: parseInt(matriculateId),
        date: currentRequest.prescription_date_parsed ? currentRequest.prescription_date_parsed : undefined,
      });

      if (!data) {
        throw new Error('Hay problemas de conexión con el sistema de Facturación. ' +
          'Temporalmente no se podrá enviar la solicitud.');
      }

      setPrestaciones({
        modules: data.modules ?? [],
        additionals: data.additionals ?? [],
      });
    } catch (err) {
      alert(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);

    getRequest(id)
      .then(response => {
        if (!response) {
          return;
        }

        const { request, files: newFiles } = response;

        if (!request.id || !request.insurer_id) {
          alert('Se produjo un error al intentar recuperar la información de la presentación. ' +
            'Por favor, intente nuevamente más tarde', () => window.location.href = '/solicitudes');
          console.error('Edit-Sancor', `No se pudo recuperar información sobre la solicitud #${ id }`);
          console.info(request);
          return;
        }

        if (request.status_id === 1 && !request.authorization_validated) {
          updateSubmission({
            insurer_id: request.insurer_id,
            insurer_name: request.insurer_shortname,
            request_id: request.id,
            previously_authorized: true,
          });

          history.replace('/solicitudes/step-authorization-validation');
          return;
        }

        setFiles(newFiles);
        setRequest(request);
        fetchPrestaciones(request);
      })
      .catch(err => {
        setDisableSend(true);
        alert('Se produjo un error al intentar recuperar la información de la presentación. ' +
          'Por favor, intente nuevamente más tarde');
        console.error('Edit', 'Error inesperador al intentar recuperar la presentación');
        console.info(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // Control del estado del botón de agregar sesiones
  useEffect(() => {
    if (!request || request.sessions.length >= request.max_sessions) {
      setNewSessionDisabled(true);
      return;
    }

    setNewSessionDisabled(false);
  }, [request?.sessions, request?.max_sessions]);

  if (!request) {
    return <>Cargando...</>;
  }

  const handleBillingDraft = async () => {
    setFormValidation({});

    setLoading(true);

    updateRequest({
      ...request,
      status_id: 1,
    }, files, prestaciones)
      .then(res => {
        if (!res || !res.request.status_id || res.request.status_id !== 1) {
          console.error('EditSancor4', 'Error al enviar la solicitud');
          console.info(res);
          alert('Ha ocurrido un error al enviar la solicitud. Por favor intente nuevamente.');
          setLoading(false);
          return;
        }

        alert('La solicitud se envió correctamente', () => {
          history.push('/solicitudes');
        });
      })
      .catch(err => {
        console.error('EditSancor4', 'Error inesperado al intentar enviar la solicitud');
        console.info(err);
        alert('Ha ocurrido un error al enviar la solicitud. Por favor intente nuevamente.');
        setLoading(false);
      });
  };

  const handleFormValidation = () => {
    if (!request) {
      return;
    }

    let newFormValidation = {};

    let toValidate = [...document.getElementsByClassName('form-control')] as HTMLInputElement[];
    toValidate = toValidate.filter(e => !e.disabled && e.required);

    let missing = false;
    // Validar campos required
    toValidate.forEach(field => {
      if (field.required && !field.disabled && !field.value?.trim()) {
        field.classList.add('is-invalid');

        missing = true;
      } else if (field.inputMode === 'numeric') {
        if (!/^\d+$/.test(field.value)) {
          newFormValidation = {
            ...newFormValidation,
            [field.name]: false,
          };
        }
      } else {
        field.classList.remove('is-invalid');
      }
    });

    // Validar file inputs required
    toValidate = [...document.getElementsByClassName('custom-file-input')] as HTMLInputElement[];
    toValidate.forEach(field => {
      if (field.required && !field.disabled && !files[field.dataset.key as AttachmentType]) {
        field.classList.add('is-invalid');

        missing = true;
      }
    });

    // Validar cantidad de sesiones
    if (request.sessions.length === 0) {
      newFormValidation = {
        ...newFormValidation,
        sessions: false,
      };

      missing = true;
    }

    if (missing) {
      setFormValidation(newFormValidation);
      alert('Todos los campos marcados con * son obligatorios. ' +
        'Por favor complete los faltantes antes de enviar la solicitud.');
      return false;
    }

    // Validar cantidad de sesiones
    if (!request.previously_authorized && request.sessions.length > request.max_sessions) {
      alert(`Únicamente se pueden cargar hasta ${ request.max_sessions } sesiones.`);
      return false;
    }

    return true;
  };

  // Confirmación de anulación de autorización
  const handleBillingAnnulmentConfirmation = () => {
    if (!request) {
      return;
    }

    // Algunas aseguradoras dan la opción de agregar un moitivo por el cual se solicita
    // la anulación
    if (insurersRequiringReasonsToAnnul.includes(request.insurer_id)) {
      setAnnulmentReason('');
      setShowAnnulmentModal(true);
      return;
    }

    setConfirm(true, '¿Estás seguro que deseas anular la autorización?', 'annulment');
  };

  // Confirmación de envío de Solicitud
  const handleBillingConfirmation = () => {
    if (!handleFormValidation()) {
      return;
    }

    setConfirm(true, '¿Estás seguro que deseas enviar esta solicitud?', 'billing');
  };

  // Gestor de respuestas de confirmaciones
  const handleConfirmation = (action: string) => {
    if (action === 'billing') {
      handleBillingRequest();
    } else if (action === 'annulment') {
      handleBillingAnnulment();
    }

    setConfirm(false);
  };

  // Envío de solicitudes
  const handleBillingRequest = () => {
    setFormValidation({});

    setLoading(true);

    updateRequest({
      ...request,
      status_id: 2,
    }, files, prestaciones)
      .then(res => {
        if (!res || !res.request.status_id) { // Error interno
          console.error('Edit', 'Error al enviar la solicitud');
          console.info(res);
          alert(`Ha ocurrido un error al enviar la solicitud. Por favor intente nuevamente.`);
          return;
        }

        alert('La solicitud se envió correctamente', () => {
          // history.push('/solicitudes');
        });
      })
      .catch(err => {
        console.error('Edit', 'Error inesperado al intentar enviar la solicitud');
        console.info(err);
        alert('Ha ocurrido un error inesperado al enviar la solicitud. Por favor intente nuevamente.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Anulación de autorizaciones
  const handleBillingAnnulment = async () => {
    try {
      setLoading(true);

      const response = await post('insurers/annul', {
        id,
        insurer_id: request.insurer_id,
        recipient_id: request.recipient_id,
        authorization_id: request.authorization_id,
        reason: annulmentReason,
      });

      // Errores
      if (response.status === 'error') {
        if (response.message) {
          console.error('Edit', 'Error al intentar anular la autorización online');
          console.info(response);
          alert(response.message);
        } else {
          console.error('Edit', 'Error desconocido al intentar anular la autorización online');
          console.info(response);
          alert('Hubo un problema al intentar anular la autorización de práctica. ' +
            'Por favor, intente nuevamente más tarde');
        }

        setLoading(false);
        return;
      }

      alert(`La autorización fue anulada por la Obra Social`, () => {
        history.replace(`/solicitudes`);
      });
    } catch (err) {
      console.error('Edit', 'Error imprevisto al intentar anular la autorización online');
      console.info(err);
      setLoading(false);
      alert('Ha ocurrido un error al anular la solicitud. Por favor intente nuevamente.');
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement> | DatePickerChangeEvent) => {
    const { target } = event;

    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const extra: Partial<Request> = {};

    // Si cambió el número de afiliado, borramos el nombre
    if (name === 'recipient_id') {
      extra['recipient_name'] = undefined;
    }

    // Guardamos el nuevo valor
    setRequest({
      ...request,
      ...extra,
      [name]: value,
    } as Request);
  };

  const handleNewSession = () => {
    if (!newSessionDate) {
      return;
    }

    if (request.sessions.find(s => s.date === newSessionDate)) {
      alert('No se puede agregar más de una sesión por día');
      return;
    }

    const newSessions = [
      ...request.sessions,
      {
        date: newSessionDate,
        date_parsed: newSessionDate.toISOString().split('T')[0],
        authorization_id: '',
        coinsurance: '0.0',
        online: !request.previously_authorized,
      },
    ].sort((a, b) => (a.date?.getTime() ?? 0) - (b.date?.getTime() ?? 0));

    // Agregamos al arreglo de fechas de sesiones la nueva fecha
    setRequest({
      ...request,
      sessions: newSessions,
    });

    // Ocultamos el modal
    setShowSessionsModal(false);
  };

  const handleRemoveSession = (target: number) => {
    // Filtramos del arreglo de sesiones el índice correspondiente
    setRequest({
      ...request,
      sessions: request.sessions.filter((value, idx) => idx !== target),
    } as Request);
  };

  const showNewSessionModal = async () => {
    // Si no hay fecha de sesión definida (no se cargó ninguna), tomamos la fecha
    // de la prescripción y, en su defecto, la de la autorización
    if (!request.sessions.length) {
      setNewSessionDate(
        request.prescription_date
          ? request.prescription_date
          : request.authorization_date,
      );
    } else {
      const lastSessionDate = request.sessions.map(({ date }) => date).sort((a, b) => b.getTime() - a.getTime())[0];
      const tentativeSessionDate = new Date(lastSessionDate.getTime() + 60 * 60 * 24 * 1000);

      if (tentativeSessionDate < new Date()) {
        setNewSessionDate(tentativeSessionDate);
      }
    }

    let sessionDateMax;
    if (request.prescription_date) {
      // Si la autorización fue realizada previamente y no tenemos fecha de autorización, utilizamos la fecha de
      // prescripción para calcular los límites de las fechas de sesiones
      setNewSessionDateMin(request.prescription_date);

      sessionDateMax = new Date(request.prescription_date.valueOf());
      sessionDateMax.setDate(request.prescription_date.getDate() + 60);
    } else if (request.authorization_date) {
      // Si la solicitud tiene autorización online utilizamos la fecha de autorización para calcular los límites
      // de las fechas de las sesiones
      setNewSessionDateMin(request.authorization_date);

      sessionDateMax = new Date(request.authorization_date.valueOf());
      sessionDateMax.setDate(request.authorization_date.getDate() + 60);
    }

    // Si el tiempo máximo calculado supera la fecha actual, utilizamos la fecha del día
    if (sessionDateMax && new Date().getTime() < sessionDateMax.getTime()) {
      setNewSessionDateMax(new Date());
    } else {
      setNewSessionDateMax(sessionDateMax);
    }

    // Mostramos el modal
    setShowSessionsModal(true);
  };

  const handleNewObservation = async (obserbation: string) => {
    setLoading(true);

    const result = await createObservation(request, obserbation);

    if (result.status === 'ok') {
      setRequest({
        ...request,
        observations: result.request.observations,
      });
    }

    setLoading(false);

    return result.status === 'ok';
  };

  return (
    <div className="content">
      <Row>
        <Col sm={12}>
          <Card>
            <CardHeader>
              <Row style={{ alignItems: 'center' }}>
                <Col sm={8}>
                  <CardTitle tag="h5">
                    Presentación #{ id }{ request.insurer_shortname ? ' - ' + request.insurer_shortname : '' }
                  </CardTitle>
                </Col>
                <Col sm={4} className="text-right">
                  <Button className="btn-round" color="primary" onClick={ () => history.push('/solicitudes') }>
                    Volver
                  </Button>
                </Col>
              </Row>
              <ObservationsAlert observations={ request.observations } />
            </CardHeader>
            <CardBody>
              <Form>
                <FormGroup>
                  <Row form>
                    <Col sm={6}>
                      <Label for="authorization_transaction_id">Número de Aprobación</Label>
                      <FakeInput value={ request.authorization_transaction_id } />
                    </Col>
                    <Col sm={6}>
                      <Label for="authorization_date">Fecha de Aprobación</Label>
                      <FakeInput value={ request.authorization_date_parsed?.split('-').reverse().join('/') } />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row form>
                    <Col sm={6}>
                      <Label for="authorization_id">Número de Formulario 4</Label>
                      <FakeInput value={ request.authorization_id } />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row form>
                    <Col sm={6}>
                      <Label for="recipient_id">Número de Afiliado</Label>
                      <FakeInput value={ request.recipient_id } />
                    </Col>
                    <Col sm={6}>
                      <Label for="recipient_name">Nombre del Afiliado</Label>
                      <FakeInput value={ request.recipient_name } />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row form>
                    <Col sm={6}>
                      <Label>Fecha Prescripción Médica</Label>
                      <FakeInput value={ request.prescription_date_parsed?.split('-').reverse().join('/') } />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Label for="diagnosis">Diagnóstico <Required /></Label>
                  <Input
                    id="diagnosis"
                    name="diagnosis"
                    type="textarea"
                    invalid={ formValidation.diagnosis === false }
                    value={ request.diagnosis ?? '' }
                    onChange={ handleInputChange }
                    disabled={ isLoading }
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Fechas de sesiones{
                    !request.previously_authorized
                      ? `: ${ request.max_sessions } autorizadas, ${ request.sessions.length } cargadas`
                      : ''
                  }</Label>
                  <Button
                    className="d-block"
                    color="primary"
                    onClick={ showNewSessionModal }
                    disabled={ isLoading || newSessionDisabled }
                  >
                    Agregar sesión
                  </Button>
                  <small className={`mt-2 text-muted ${ request.authorization_date ? 'd-none' : 'd-block' }`}>
                    Se tiene que seleccionar la fecha de autorización antes de poder agregar las sesiones
                  </small>
                  <Table
                    className='align-items-center table-flush mb-0 col-sm-6'
                    style={{ fontSize: '.8rem' }}
                    responsive={ false }
                  >
                    <thead>
                      <tr>
                        <th>FECHA SESIÓN</th>
                        <th>ACCIONES</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        request.sessions && Array.isArray(request.sessions)
                          ? request.sessions
                            .sort((a, b) => a.date.getTime() - b.date.getTime())
                            .map(({ date }, i) => (
                              <tr key={ i }>
                                <td>
                                  { date.getDate() }/
                                  { (date.getMonth() + 1).toString().padStart(2, '0') }/
                                  { date.getFullYear() }
                                </td>
                                <td className='text-left table__mobile--body__actions'>
                                  <Button color="link" onClick={ () => handleRemoveSession(i) } disabled={ isLoading } >
                                    <i className="fa fa-times" style={{ color: 'red' }}></i>
                                  </Button>
                                </td>
                              </tr>
                            ))
                          : null
                      }
                    </tbody>
                  </Table>
                  {
                    request.sessions.length === 0
                      ? <InputError message="Tiene que cargar al menos una sesión" />
                      : null
                  }
                </FormGroup>
                <FormGroup row>
                  <Col sm={6}>
                    <Label for="practice_id">Tipo de práctica <Required /></Label>
                    <FakeInput value={ request.practice_text } />
                  </Col>
                  {
                    request.additional_id
                      ? <Col sm={6}>
                          <Label for="additional_id">Práctica adicional</Label>
                          <FakeInput value={ request.additional_text } />
                        </Col>
                      : null
                  }
                </FormGroup>
                <Attachments
                  insurer_id={ request.insurer_id }
                  files={ files }
                  sessions={ request.sessions.length }
                  previously_authorized={ request.previously_authorized }
                  setFiles={ setFiles }
                  disabled={ isLoading }
                  impairment={ request.impairment }
                  rebilling={ request.rebilling }
                  exceptional={ request.exceptional }
                  setLoading={ setLoading }
                />
                <FormGroup>
                  <Label>Observaciones</Label>
                  <ObservationList
                    observations={ request.observations }
                    disabled={ isLoading }
                    createObservation={ handleNewObservation }
                  />
                </FormGroup>
                <FormGroup>
                  <div className="text-center">
                    <Button
                      type="button"
                      color="danger"
                      className={ `btn-round mr-4 ${ request.previously_authorized ? 'd-none' : '' }` }
                      onClick={ handleBillingAnnulmentConfirmation }
                      disabled={ isLoading }
                    >
                      Anular Autorización
                    </Button>
                    <Button
                      type="button"
                      color="primary"
                      className="btn-round mr-4"
                      onClick={ handleBillingDraft }
                      disabled={ isLoading }
                    >
                      Guardar Borrador
                    </Button>
                    <Button
                      type="button"
                      className="btn-round"
                      onClick={ handleBillingConfirmation }
                      disabled={ isLoading || disableSend }
                    >
                      Enviar Solicitud
                    </Button>
                  </div>
                </FormGroup>
              </Form>
              <AlertModal isOpen={ showAlert } message={ message } onClose={ alertCallback } />
              <Modal isOpen={ showSessionsModal } size="md" centered>
                <ModalHeader>Agregar fecha de sesión</ModalHeader>
                <ModalBody>
                  <FormGroup className='card form-group-dates'>
                    <Label>Fecha</Label>
                    <DatePicker
                      id="session_date"
                      name="session_date"
                      onChange={ date => setNewSessionDate(date ? date : undefined) }
                      dateFormat="dd/MM/yyyy"
                      locale="es"
                      placeholderText="Fecha de la sesión"
                      minDate={ newSessionDateMin ?? '' }
                      maxDate={ newSessionDateMax ?? '' }
                      excludeDates={ request.sessions.map(({ date }) => date) }
                      selected={ newSessionDate }
                      className="form-control"
                      autoComplete="off"
                    />
                  </FormGroup>
                </ModalBody>
                <ModalFooter>
                  <Button color="success" onClick={ () => handleNewSession() }>
                    Agregar
                  </Button>
                  <Button color="primary" onClick={ () => setShowSessionsModal(false) }>
                    Cancelar
                  </Button>
                </ModalFooter>
              </Modal>
              <ConfirmModal
                isOpen={ confirm }
                title={ confirmTitle }
                action={ confirmAction }
                onClose={ () => setConfirm(false) }
                onConfirm={ handleConfirmation }
              />
              <Modal isOpen={ showAnnulmentModal } size="md" centered>
                <ModalHeader>Anular Autorización</ModalHeader>
                <ModalBody>
                  <FormGroup>
                    <Label>Motivo de la Solicitud de Anulación</Label>
                    <Input
                      id="annulment_reason"
                      name="annulment_reason"
                      type="textarea"
                      onChange={ ({ target }) => setAnnulmentReason(target.value) }
                      className="form-control"
                      required
                    />
                  </FormGroup>
                </ModalBody>
                <ModalFooter>
                  <Button color="success" onClick={ () => handleBillingAnnulment() }>
                    Anular
                  </Button>
                  <Button color="primary" onClick={ () => setShowAnnulmentModal(false) }>
                    Cancelar
                  </Button>
                </ModalFooter>
              </Modal>
            </CardBody>
            <CardFooter>
              <Row style={{ alignItems: 'center' }}>
                <Col sm={8}></Col>
                <Col sm={4} className="text-right">
                  <Button className="btn-round" color="primary" onClick={ () => history.push('/solicitudes') }>
                    Volver
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
