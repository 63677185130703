import StepInsurerSelectionRequests from './views/Requests/StepInsurerSelection'
import StepAffiliateValidationRequests from './views/Requests/StepAffiliateValidation'
import StepOnlineAuthorizationRequests from './views/Requests/StepOnlineAuthorization'
import StepAuthorizationValidation from 'views/Requests/StepAuthorizationValidation'

import ListRequests from './views/Requests/List'
import ViewRequests from './views/Requests/View'

import EditRequests from './views/Requests/Edit'
import EditSessionsRequests from './views/Requests/EditSessions'
import EditSancor from 'views/Requests/EditSancor'
import EditSancor4 from 'views/Requests/EditSancor4'
import EditSwiss from 'views/Requests/EditSwiss'
import EditFederada from 'views/Requests/EditFederada'

import ListBills from 'views/Bills/List'
import ShowBill from 'views/Bills/Show'
import EditBill from 'views/Bills/Edit'

import Help from 'views/Help/Help'

var routes = [
  {
    path: '/step-insurer-selection',
    component: StepInsurerSelectionRequests,
    layout: '/solicitudes',
    hide: true
  },
  {
    path: '/step-affiliate-validation',
    component: StepAffiliateValidationRequests,
    layout: '/solicitudes',
    hide: true
  },
  {
    path: '/step-authorization-validation',
    component: StepAuthorizationValidation,
    layout: '/solicitudes',
    hide: true
  },
  {
    path: '/step-online-authorization',
    component: StepOnlineAuthorizationRequests,
    layout: '/solicitudes',
    hide: true
  },
  {
    path: '/:id/edit-sessions',
    component: EditSessionsRequests,
    layout: '/solicitudes',
    hide: true
  },
  {
    path: '/:id/edit-sancor',
    component: EditSancor,
    layout: '/solicitudes',
    hide: true
  },
  {
    path: '/:id/edit-sancor-4',
    component: EditSancor4,
    layout: '/solicitudes',
    hide: true
  },
  {
    path: '/:id/edit-swiss',
    component: EditSwiss,
    layout: '/solicitudes',
    hide: true
  },
  {
    path: '/:id/edit-federada',
    component: EditFederada,
    layout: '/solicitudes',
    hide: true
  },
  {
    path: '/:id/edit',
    component: EditRequests,
    layout: '/solicitudes',
    hide: true
  },
  {
    path: '/:id',
    component: ViewRequests,
    layout: '/solicitudes',
    hide: true
  },
  {
    path: '',
    name: "Gestión de Autorización",
    displayName: "Gestión de Autorización",
    icon: "nc-icon nc-box",
    component: ListRequests,
    layout: '/solicitudes'
  },
  {
    path: "/:id/edit",
    name: "Facturación online",
    displayName: "Facturación online",
    icon: "nc-icon nc-paper",
    component: EditBill,
    layout: '/facturacion',
    hide: true
  },
  {
    path: "/:id",
    name: "Facturas",
    displayName: "Facturación online",
    icon: "nc-icon nc-paper",
    component: ShowBill,
    layout: '/facturacion',
    hide: true
  },
  {
    path: '',
    name: "Facturación online",
    displayName: "Facturación online",
    icon: "nc-icon nc-paper",
    component: ListBills,
    layout: '/facturacion',
    hide: true
  },
  {
    path: '',
    name: "Ayuda",
    icon: "nc-icon nc-bulb-63",
    component: Help,
    layout: '/help'
  }
]

export default routes
