import React, { useContext, useEffect, useState } from 'react'
import CollapsibleCard from '../../components/CollapsibleCard'
import { Col, Row, FormGroup, Input } from 'reactstrap'
import { getAll } from 'services/ApiService'
import { useAlertPlus } from 'hooks'
import AlertModal from 'components/AlertModal'
import { getCookie } from 'helpers'
import config from 'config'
import { UserContext } from 'context/UserContext'

function RequestFilters(props) {
  const { changeFilter, currentOptions } = props

  const user = useContext(UserContext)

  const [ insurers, setInsurers ] = useState([])
  const [ fechaRemitos, setFechaRemitos ] = useState([])
  const [ enableRemitoFilter, setEnableRemitoFilter ] = useState(false);
  const [ showAlert, message, alertCallback, alert ] = useAlertPlus('')

  useEffect(() => {
    getAll('ooss', {
      sort: {
        field: 'codigo',
        dir: 1
      }
    })
    .then(res => {
      setInsurers(res.rows)
    })
    .catch(err => {
      alert('Ha ocurrido un error al obtener el listado de Obras Sociales, temporalmente no se podrá filtrar por obra social.')
      console.log(err)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!currentOptions.ooss_id || currentOptions.ooss_id === 'any') {
      setEnableRemitoFilter(false)
      return
    }

    if (!currentOptions.estado_id || parseInt(currentOptions.estado_id) !== 5) {
      setEnableRemitoFilter(false)
      return
    }

    fetch(`${ config.baseUrl }/matriculates/${ user.getMatricula() }/remitos/${ currentOptions.ooss_id }`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCookie('accessToken')
      }
    })
    .then(res => res.json())
    .then(res => {
      if (res.status === 'error') {
        console.error('Filters', 'Error al recuperar los remitos del matriculado')
        console.info(res)
        return
      }

      setFechaRemitos(res.payload)

      setEnableRemitoFilter(true)
    })
    .catch(err => {
      console.error('Filters', 'Error al recuperar los remitos del matriculado')
      console.error(err)
      return
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOptions.ooss_id, currentOptions.estado_id])

  return (
    <CollapsibleCard title="Filtros">
      <Row>
        <Col md="4">
          <FormGroup>
            <label>Estado</label>
            <Input type="select" onChange={ (event) => changeFilter('estado_id', event.target.value) } defaultValue="[1, 4]">
              <option value="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]">Todos</option>
              <option value="[1, 4]">Pendientes</option>
              <option value="1">Borrador</option>
              <option value="2">Solicitada</option>
              <option value="4">Observada</option>
              <option value="9">Anulada</option>
              <option value="[3, 6, 7, 8]">Auditada</option>
              <option value="5">Procesada</option>
              <option value="10">Fuera de Término</option>
            </Input>
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <label>Obra Social</label>
            <Input type="select" onChange={ (event) => changeFilter('ooss_id', event.target.value) } defaultValue="any">
              <option value="any">Todas</option>
              {
                insurers && Array.isArray(insurers)
                ? insurers.map(insurer => <option key={'insurer_' + insurer.id} value={ insurer.id }>{ insurer.codigo }</option>)
                : null
              }
            </Input>
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <label>Mes de Prestación</label>
            <Input
              type="select"
              onChange={ (event) => changeFilter('fecha_remito', event.target.value) }
              defaultValue="any"
              disabled={ !enableRemitoFilter }
            >
              <option value="any">Todos</option>
              {
                fechaRemitos && Array.isArray(fechaRemitos)
                ? fechaRemitos.map(fecha => <option key={'fecha_' + fecha} value={ fecha }>{ fecha }</option>)
                : null
              }
            </Input>
          </FormGroup>
        </Col>
        <AlertModal isOpen={ showAlert } message={ message } onClose={ alertCallback } />
      </Row>
    </CollapsibleCard>
  )
}

export default RequestFilters