import React, { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col } from 'reactstrap';
import { useAlertPlus, useConfirm } from 'hooks';
import Required from 'components/Required';
import DatePicker, { DatePickerChangeEvent } from 'components/DatePicker';
import ConfirmModal from 'components/ConfirmModal';
import AlertModal from 'components/AlertModal';
import { useHistory } from 'react-router-dom';
import Attachments from 'components/Requests/Attachments';
import ObservationsAlert from 'components/Requests/ObservationAlert';
import Sessions from './Sessions';
import { useUser } from 'context';
import {
  AttachmentType,
  InsurerPractices,
  Request,
  RequestFiles,
  Session } from 'types/Request';
import { getPractices } from 'features/practices/practicesAPI';
import { getRequest, updateRequest, createObservation } from 'features/requests/requestsAPI';
import { getErrorMessage } from 'helpers/errors';
import ObservationList from 'components/Requests/ObservationList';
import { useStore } from 'context/StoreContext';

export default function EditFederada() {
  const { id } = useParams<{ id: string }>();

  const { updateSubmission } = useStore();
  const history = useHistory();
  const { getMatricula } = useUser();

  const matriculateId = getMatricula();
  if (!matriculateId) {
    return;
  }

  const [request, setRequest] = useState<Request>();

  const [files, setFiles] = useState<RequestFiles>({});

  const [prestaciones, setPrestaciones] = useState<InsurerPractices>({
    modules: [],
    additionals: [],
  });

  const [formValidation, setFormValidation] = useState<Partial<Record<keyof Request, boolean>>>({});

  const [disableSend, setDisableSend] = useState(false);

  const [isLoading, setLoading] = useState(true);

  const [showAlert, message, alertCallback, alert] = useAlertPlus('');
  const [confirm, confirmTitle, confirmAction, setConfirm] = useConfirm(false);

  const fetchPrestaciones = async (newRequest?: Request) => {
    try {
      if (!request && !newRequest) {
        return;
      }

      const currentRequest = newRequest ? newRequest : request!;

      const data = await getPractices({
        insurerId: currentRequest.insurer_id,
        matriculateId: parseInt(matriculateId),
        date: currentRequest.prescription_date_parsed ? currentRequest.prescription_date_parsed : undefined,
      });

      if (!data || !data.modules || !Array.isArray(data.modules)) {
        throw new Error('Hay problemas de conexión con el sistema de Facturación. ' +
          'Temporalmente no se podrá enviar la solicitud.');
      }

      if (data.modules.length === 0) {
        throw new Error('Hubo problemas recuperando las prácticas disponibles. ' +
          'Temporalmente no se podrá enviar la solicitud.');
      }

      setPrestaciones({
        modules: data.modules ?? [],
        additionals: data.additionals ?? [],
      });
    } catch (err) {
      setDisableSend(true);
      alert(getErrorMessage(err));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);

    getRequest(parseInt(id))
      .then(response => {
        if (!response) {
          return;
        }

        const { request, files: newFiles } = response;

        if (!request.id || !request.insurer_id) {
          alert('Se produjo un error al intentar recuperar la información de la presentación. ' +
            'Por favor, intente nuevamente más tarde', () => window.location.href = '/solicitudes');
          console.error('Edit-Federada', `No se pudo recuperar información sobre la solicitud #${ id }`);
          console.info(request);
          return;
        }

        if (request.status_id === 1 && request.previously_authorized && !request.authorization_validated) {
          updateSubmission({
            insurer_id: request.insurer_id,
            insurer_name: request.insurer_shortname,
            request_id: request.id,
            previously_authorized: true,
          });

          history.replace('/solicitudes/step-authorization-validation');
          return;
        }

        setFiles(newFiles);
        setRequest(request);
        fetchPrestaciones(request);
      })
      .catch(err => {
        setDisableSend(true);
        alert('Se produjo un error al intentar recuperar la información de la presentación. ' +
          'Por favor, intente nuevamente más tarde');
        console.error('Edit', 'Error inesperador al intentar recuperar la presentación');
        console.info(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  if (!request) {
    return <>Cargando...</>;
  }

  const handleBillingDraft = async () => {
    setFormValidation({});

    setLoading(true);

    updateRequest({
      ...request,
      status_id: 1,
    }, files, prestaciones)
      .then(res => {
        if (!res || !res.request.status_id || res.request.status_id !== 1) {
          console.error('EditFederada', 'Error al guardar el borrador de la presentación');
          console.info(res);
          alert('Ha ocurrido un error al enviar la presentación. Por favor intente nuevamente.');
          return;
        }

        alert('Se guardó correctamente el borrador de la presentación.');
      })
      .catch(err => {
        console.error('EditFederada', 'Error inesperado al intentar guardar el borrador de la presentación');
        console.info(err);
        alert('Ha ocurrido un error al enviar la presentación. Por favor intente nuevamente.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFormValidation = () => {
    if (!request) {
      return;
    }

    let newFormValidation = {};

    let toValidate = [...document.getElementsByClassName('form-control')] as HTMLInputElement[];
    toValidate = toValidate.filter(e => !e.disabled && e.required);

    let missing = false;
    // Validar campos required
    toValidate.forEach(field => {
      if (field.required && !field.disabled && !field.value?.trim()) {
        field.classList.add('is-invalid');

        missing = true;
      } else {
        field.classList.remove('is-invalid');
      }
    });

    // Validar file inputs required
    toValidate = [...document.getElementsByClassName('custom-file-input')] as HTMLInputElement[];
    toValidate.forEach(field => {
      if (field.required && !field.disabled && !files[field.dataset.key as AttachmentType]) {
        field.classList.add('is-invalid');

        missing = true;
      }
    });

    // Validar cantidad de sesiones
    if (request.sessions.length === 0) {
      newFormValidation = {
        ...newFormValidation,
        sessions: false,
      };

      missing = true;
    }

    if (missing) {
      setFormValidation(newFormValidation);
      alert('Todos los campos marcados con * son obligatorios. ' +
        'Por favor complete los faltantes antes de enviar la solicitud.');
      return false;
    }

    return true;
  };

  // Confirmación de envío de Solicitud
  const handleBillingConfirmation = () => {
    if (!handleFormValidation()) {
      return;
    }

    setConfirm(true, '¿Estás seguro que deseas enviar esta solicitud?', 'billing');
  };

  // Gestor de respuestas de confirmaciones
  const handleConfirmation = (action: string) => {
    if (action === 'billing') {
      handleBillingRequest();
    }

    setConfirm(false);
  };

  // Envío de solicitudes
  const handleBillingRequest = () => {
    setFormValidation({});

    setLoading(true);

    updateRequest({
      ...request,
      status_id: 2,
    }, files, prestaciones)
      .then(res => {
        if (!res || !res.request.status_id || res.request.status_id !== 2) {
          console.error('EditFederada', 'Error al enviar la solicitud');
          console.info(res);
          alert('Ha ocurrido un error al enviar la solicitud. Por favor intente nuevamente.');
          return;
        }

        alert('La solicitud se envió correctamente', () => {
          history.push('/solicitudes');
        });
      })
      .catch(err => {
        console.error('EditFederada', 'Error inesperado al intentar enviar la solicitud');
        console.info(err);
        alert('Ha ocurrido un error al enviar la solicitud. Por favor intente nuevamente.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement> | DatePickerChangeEvent) => {
    const { target } = event;

    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const extra: Partial<Request> = {};

    // Si cambió el número de afiliado, borramos el nombre
    if (name === 'recipient_id') {
      extra['recipient_name'] = undefined;
    }

    // Guardamos el nuevo valor
    setRequest({
      ...request,
      ...extra,
      [name]: value,
    });
  };

  const setSessions = (sessions: Session[]) => {
    setRequest({
      ...request,
      sessions,
    });
  };

  const setRecipient = (name: string) => {
    setRequest({
      ...request,
      recipient_name: name,
    });
  };

  const handleNewObservation = async (obserbation: string) => {
    setLoading(true);

    const result = await createObservation(request, obserbation);

    if (result.status === 'ok') {
      setRequest({
        ...request,
        observations: result.request.observations,
      });
    }

    setLoading(false);

    return result.status === 'ok';
  };

  return (
    <div className="content">
      <Row>
        <Col sm={12}>
          <Card>
            <CardHeader>
              <Row style={{ alignItems: 'center' }}>
                <Col sm={8}>
                  <CardTitle tag="h5">Presentación #{ id }
                    { request?.insurer_shortname ? ' - ' + request.insurer_shortname : '' }</CardTitle>
                </Col>
                <Col sm={4} className="text-right">
                  <Button className="btn-round" color="primary" onClick={ () => history.push('/solicitudes') }>
                    Volver
                  </Button>
                </Col>
              </Row>
              <ObservationsAlert observations={ request?.observations } />
            </CardHeader>
            <CardBody>
              <Form>
                <FormGroup>
                  <Row form>
                    <Col sm={6}>
                      <Label for="recipient_id">Número de Afiliado <Required /></Label>
                      <Input
                        id="recipient_id"
                        name="recipient_id"
                        value={ request?.recipient_id ?? '' }
                        invalid={ formValidation.recipient_id === false }
                        onChange={ handleInputChange }
                        disabled={ isLoading || !request.previously_authorized || !!request.sessions.length }
                        required
                      />
                    </Col>
                  </Row>
                </FormGroup>
                {
                  request.recipient_name
                    ? <FormGroup>
                        <Row form>
                          <Col sm={6}>
                            <Label for="recipient_name">Nombre del Afiliado</Label>
                            <Input
                              id="recipient_name"
                              name="recipient_name"
                              defaultValue={ request.recipient_name }
                              disabled={ true }
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                    : null
                }
                <FormGroup>
                  <Row form>
                    <Col sm={4}>
                      <Label for="prescription_date">Fecha Prescripción Médica <Required /></Label>
                      <DatePicker
                        id="prescription_date"
                        name="prescription_date"
                        selected={ request.prescription_date ?? '' }
                        dateFormat="dd/MM/yyyy"
                        locale="es"
                        placeholderText="Fecha Prescripción"
                        className="date-picker form-control"
                        maxDate={ new Date() }
                        minDate={ new Date(Date.now() - 90 * 24 * 60 * 60 * 1000) }
                        disabled={ isLoading || request.sessions.length > 0 }
                        onChange={ (date: Date | null) => {
                          return handleInputChange({
                            target: {
                              name: 'prescription_date',
                              type: 'datepicker',
                              value: date,
                            },
                          });
                        }}
                        autoComplete="off"
                        required
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Label for="diagnosis">Diagnóstico <Required /></Label>
                  <Input
                    id="diagnosis"
                    name="diagnosis"
                    type="textarea"
                    invalid={ formValidation.diagnosis === false }
                    value={ request.diagnosis ?? '' }
                    onChange={ handleInputChange }
                    disabled={ isLoading }
                    required
                  />
                </FormGroup>
                <FormGroup row>
                  <Col md="6">
                    <Label for="practice_id">Tipo de práctica <Required /></Label>
                    <Input
                      id="practice_id"
                      name="practice_id"
                      type="select"
                      className="custom-select"
                      value={ request.practice_id ?? '' }
                      invalid={ formValidation.practice_id === false }
                      onChange={ handleInputChange }
                      disabled={ isLoading || request.sessions.length > 0 }
                      required
                    >
                      <option disabled value='' hidden>Seleccione una opción...</option>
                      {
                        Array.isArray(prestaciones.modules) && prestaciones.modules.map(item => (
                          <option key={ item.id } value={ item.id }>{ item.name }</option>
                        ))
                      }
                    </Input>
                  </Col>
                  {
                    Array.isArray(prestaciones.additionals) && prestaciones.additionals.length > 0
                      ? <Col sm={6}>
                          <Label for="additional_id">Práctica adicional</Label>
                          <Input
                            id="additional_id"
                            name="additional_id"
                            type="select"
                            className="custom-select"
                            value={ request.additional_id ?? '' }
                            invalid={ formValidation.additional_id === false }
                            onChange={ handleInputChange }
                            disabled={ isLoading || request.sessions.length > 0 }
                          >
                            <option value=''>Ninguna</option>
                            {
                              prestaciones.additionals.map(item => (
                                <option key={ item.id } value={ item.id }>{ item.name }</option>
                              ))
                            }
                          </Input>
                        </Col>
                      : null
                  }
                </FormGroup>
                <FormGroup>
                  <Label>Fechas de sesiones</Label>
                  <Sessions
                    requestId={ id }
                    formData={ request }
                    prestaciones={ prestaciones }
                    setSessions={ setSessions }
                    isLoading={ isLoading }
                    setLoading={ setLoading }
                    disableSend={ disableSend }
                    setRecipient={ setRecipient }
                  />
                </FormGroup>
                <Attachments
                  insurer_id={ request.insurer_id }
                  files={ files }
                  sessions={ request.sessions.length }
                  previously_authorized={ false }
                  setFiles={ setFiles }
                  disabled={ isLoading }
                  setLoading={ setLoading }
                />
                <FormGroup>
                  <Label>Observaciones</Label>
                  <ObservationList
                    observations={ request.observations }
                    disabled={ isLoading }
                    createObservation={ handleNewObservation }
                  />
                </FormGroup>
                <FormGroup>
                  <div className="text-center">
                    <Button
                      type="button"
                      color="primary"
                      className="btn-round mr-4"
                      onClick={ handleBillingDraft }
                      disabled={ isLoading }
                    >
                      Guardar Borrador
                    </Button>
                    <Button
                      type="button"
                      className="btn-round"
                      onClick={ handleBillingConfirmation }
                      disabled={ isLoading || disableSend }
                    >
                      Enviar Solicitud
                    </Button>
                  </div>
                </FormGroup>
              </Form>
              <AlertModal isOpen={ showAlert } message={ message } onClose={ alertCallback } />
              <ConfirmModal
                isOpen={ confirm }
                title={ confirmTitle }
                action={ confirmAction }
                onClose={ () => setConfirm(false) }
                onConfirm={ handleConfirmation }
              />
            </CardBody>
            <CardFooter>
              <Row style={{ alignItems: 'center' }}>
                <Col sm={8}></Col>
                <Col sm={4} className="text-right">
                  <Button className="btn-round" color="primary" onClick={ () => history.push('/solicitudes') }>
                    Volver
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
