import React, { useContext, useState } from 'react'
import { Button, FormGroup, Form, Input, Label } from 'reactstrap'
import { UserContext } from 'context'
import { useAlert } from 'hooks'
import { useHistory } from 'react-router-dom'
import { setCookie, deleteCookie } from 'helpers'
import AlertModal from 'components/AlertModal'
import config from 'config'

function Login () {
  const [ showAlert, message, alert ] = useAlert(false, '')
  const [ formData, setFormData ] = useState({
    matriculate_id: '',
    password: ''
  })

  const [ loading, setLoading ] = useState(false)

  const { setUser } = useContext(UserContext)

  const history = useHistory()

  const style = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    width: '320px'
  }

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    })
  }

  const handleSubmit = async () => {
    try {
      setLoading(true)

      const res = await fetch(`${ config.baseUrl }/auth/autogestion`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      })

      const payload = await res.json()

      if (!payload || !payload.success) {
        deleteCookie('accessToken')
        deleteCookie('sessionCookie')

        if (payload?.error?.name === 'SequelizeConnectionError') {
          alert(true, 'No se pudo autenticar la matrícula y contraseña ingresadas porque hay un problema de conexión. Por favor, intente nuevamente más tarde')
        } else {
          alert(true, 'La matrícula y/o contraseña no son correctas. Por favor, verifique que los datos ingresados sean correctos e intente nuevamente')
        }
        setLoading(false)
        return
      }

      setUser(payload.user)
      setCookie('sessionCookie', payload.sessionCookie, { days: 10 })
      setCookie('accessToken', payload.accessToken, { days: 10 })
      setLoading(false)
      history.push('/solicitudes')
    } catch (err) {
      console.log(err)
      alert(true, 'Se produjo un error al intentar validar sus datos. por favor, intente nuevamente más tarde')
      deleteCookie('accessToken')
      deleteCookie('sessionCookie')
      setLoading(false)
    }
  }

  return (
    <>
      <AlertModal isOpen={ showAlert } message={ message } onClose={ () => alert(false) } />
      <div style={ style }>
        <img src={ require('../logo-black.png') } alt="COKIBA" />
        <br /><br />
        <Form>
          <FormGroup>
            <Label htmlFor="matriculate_id">Matrícula</Label>
            <Input
              name="matriculate_id"
              type="text"
              placeholder="Ingresar el número de matrícula"
              className="text-center"
              onChange={ handleChange }
              disabled={ loading }
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="password">Contraseña</Label>
            <Input
              name="password"
              type="password"
              placeholder="Ingresar la contraseña"
              className="text-center"
              autoComplete="current-password"
              onChange={ handleChange }
              disabled={ loading }
            />
          </FormGroup>

          <Button
            color="primary"
            onClick={ handleSubmit }
            disabled={ loading }
          >
            {
              loading
              ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              : <>Iniciar Sesión</>
            }
          </Button>
        </Form>
      </div>
    </>
  )
}

export default Login
