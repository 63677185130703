import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Alert, Card, CardHeader, CardTitle, CardBody, Row, Col, Button, Form } from 'reactstrap'
import { getOne, update } from '../../services/ApiService'
import { useAlertPlus } from '../../hooks/useAlertPlus'
import AlertModal from '../../components/AlertModal'
import Uploader from './Uploader'
import config from '../../config'

import './Bill.scss'

// Lista de estados que permiten la edición
const editableStates = [
  'Pendiente',
  'Observado'
]

function Edit() {
  const { id } = useParams()
  const [bill, setBill] = useState({})
  const [alertShow, alertMessage, alertCallback, setAlert] = useAlertPlus('')
  const history = useHistory()
  const formElement = useRef(null)
  const [ isLoading, setLoading ] = useState(true)

  useEffect(() => {
    getOne('submission-orders', id)
    .then(result => {
      if(!result) {
        setLoading(false)
        return () => setAlert('No se pudo encontrar la facturación solicitada.', () => history.goBack())
      }

      if(result.message && result.message === '404 Not Found') {
        setLoading(false)
        return () => setAlert('No se pudo encontrar la facturación solicitada.', () => history.goBack())
      }

      // Si el status no permite la edición, redirigimos a la pantalla de sólo vista
      if (!editableStates.includes(result.status)) {
        setLoading(false)
        history.replace({
          pathname: `/facturacion/${ id }`,
          state: { success: true }
        })
      }

      setBill(result)
      setLoading(false)
    })
    .catch(error => {
      console.error(error)
      setLoading(false)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sendBill = async () => {
    try {
      setLoading(true)

      // Preparamos los inputs menos los archivos
      const data = {
        'status': 'presented'
      }
      new FormData(formElement.current).forEach((value, key) => data[key] = value);

      // Preparamos los archivos
      const files = {}
      const inputs = document.querySelectorAll('input[type="file"]');
      for (let i = 0; i < inputs.length; i++) {
        if (inputs[i].hasAttribute('required') && inputs[i].value === '') {
          setAlert('Faltan subir archivos requeridos para poder enviar la solicitud.')
          setLoading(false)
          return
        }

        files[inputs[i].name] = inputs[i].files[0]

      }

      // Enviamos la petición
      const res = await update('submission-orders', id, data, files)

      if (res.error) {
        setAlert('Ha ocurrido un error al guardar la solicitud, por favor intente nuevamente.')
        setLoading(false)
        return
      }

      return (
        setLoading(false),
        history.push({
          pathname: '/facturacion',
          state: { success: true }
        })
      )
    } catch(err) {
      console.log(err)
      setLoading(false)
    }
  }

  return (
    <div className='content'>
      <Row>
        <Col md='12'>
          <Card className='card-user'>
            <CardHeader>
              <Row>
                <Col md='8'>
                    <CardTitle tag='h5'>Solicitud #{ id } - { bill ? bill.status : 'Borrador' }</CardTitle>
                </Col>
                <Col md='4' className='back-button-container'>
                  <Button className='btn-round' color='primary' onClick={() => history.goBack()}>
                    Volver
                  </Button>
                </Col>
              </Row>
              {
                bill.status === 'Observado' && Array.isArray(bill.observations) && bill.observations.length
                ? <Alert color="warning">
                    <Row className='mb-2'>
                      <Col md='12'>
                        <span className='group-name observations'>
                          Observaciones
                          {
                            bill.observations.map((obs) => (
                              <p className='mt-2' key={obs.id}>
                                <span><strong>{new Date(obs.createdAt).toLocaleString('es')}</strong> - </span>
                                { obs.text }
                              </p>
                            ))
                          }
                        </span>
                      </Col>
                    </Row>
                  </Alert>
                : null
              }
            </CardHeader>
            <CardBody>
              <Form innerRef={ formElement }>
                <Row className="mb-2">
                  <Col md='2' sm='12'><strong>Obra Social</strong></Col>
                  <Col md='10' sm='12'>{ bill.ooss?.nombre } ({ bill.ooss?.cuit })</Col>
                </Row>
                <Row className="mb-2">
                  <Col md='2' sm='12'><strong>Concepto</strong></Col>
                  <Col md='10' sm='12'>{ bill.concept }</Col>
                </Row>
                <Row className="mb-2">
                  <Col md='2' sm='12'><strong>Periodo</strong></Col>
                  <Col md='10' sm='12'>{ bill.monthPeriod }/{ bill.yearPeriod }</Col>
                </Row>
                <Row className="mb-2">
                  <Col md='2' sm='12'><strong>Fecha de Solicitud</strong></Col>
                  <Col md='10' sm='12'>{ new Date(bill.createdAt).toLocaleDateString('es-AR', { dateStyle: 'short' }) }</Col>
                </Row>
                <Row className="mb-2">
                  <Col md='2' sm='12'><strong>Monto</strong></Col>
                  <Col md='10' sm='12'>$ { bill.amount }</Col>
                </Row>
                {
                  bill.attachs && bill.attachs.map(({ type, url }, idx) => {
                    return (
                      <Row className="mt-4 mb-2" key={`attach_${idx}`}>
                        <Col md='2' sm='12'><strong>{ type === 'factura' ? 'Factura' : 'Nota de Crédito' }</strong></Col>
                        <Col md='10' sm='12'>
                          <Button color="primary" onClick={ () => window.open(`${config.baseUrl}/${url}`, "_blank") }>Ver adjunto</Button>
                        </Col>
                      </Row>
                    )
                  })
                }
                {
                  bill.lastRejectReason &&
                    <Row className="mb-2">
                      <Col md='2' sm='12'><strong>Observación</strong></Col>
                      <Col md='10' sm='12'>{ bill.lastRejectReason }</Col>
                    </Row>
                }
                {
                  bill.status === 'Observado'
                  ? <Row className="mt-4 mb-2">
                      <Col md='2' sm='12'><strong>Nota de crédito</strong> *</Col>
                      <Col md='10' sm='12'>
                        <Uploader name='nota' required />
                      </Col>
                    </Row>
                  : null
                }
                <Row className="mt-4 mb-2">
                  <Col md='2' sm='12'><strong>Factura</strong> *</Col>
                  <Col md='10' sm='12'>
                    <Uploader name='factura' required />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col sm={{ offset: 2, size: 10 }}>
                      <label>Tamaño máximo por archivo: <strong>3 MB</strong></label>
                  </Col>
                </Row>
                <Row>
                  <div className='update ml-auto mr-auto'>
                    <Button className='btn-round ml-4' color='success' title='Enviar factura' onClick={ () => sendBill() } disabled={ isLoading }>
                      Enviar
                    </Button>
                  </div>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <AlertModal isOpen={alertShow} message={alertMessage} onClose={alertCallback} />
    </div>
  )
}

export default Edit
