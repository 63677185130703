export const scrollToTop = () => window.scrollTo(0, 0)

// update object with a second object
export const mergeModel = (firstObj, secObj, model) => {
  let merged = {}
  for (const key in secObj) {
    if (typeof firstObj[key] === 'object' && typeof secObj[key] === 'object') {
      if (Array.isArray(firstObj[key]) && Array.isArray(secObj[key])) {
        merged[key] = []
        for (const index in secObj[key]) {
          merged[key].push(mergeModel(firstObj[key][index] || {}, secObj[key][index], model))
        }
      } else {
        merged[key] = mergeModel(firstObj[key], secObj[key], model)
      }
    } else {
      // key doesn't exist in firstObj but its allowed in model
      if (!firstObj[key] && model.indexOf(key) !== -1) {
        merged[key] = secObj[key]
      }
      // here we override the first value with the second value
      else if (firstObj[key]) {
        merged[key] = secObj[key]
      }
    }
  }
  for (const key in firstObj) {
    if (typeof firstObj[key] === 'object' && typeof secObj[key] === 'object') {
      if (Array.isArray(firstObj[key]) && Array.isArray(secObj[key])) {
        merged[key] = merged[key] || []
        for (const index in firstObj[key]) {
          merged[key].push(mergeModel(firstObj[key][index], secObj[key][index] || {}, model))
        }
      } else {
        merged[key] = merged[key] || mergeModel(firstObj[key], secObj[key], model)
      }
    } else {
      if (secObj !== undefined && secObj !== null) {
        merged[key] = merged[key] || (secObj[key] !== undefined && secObj[key] !== null ? secObj[key] : firstObj[key])
      } else {
        merged[key] = merged[key] || firstObj[key]
      }
    }
  }
  return merged
}

export * from './requiredAttachs'
export * from './getQueryParam'
export * from './reponseError'
export * from './mergeArrays'
export * from './getMinimum'
export * from './compareDates'
export * from './bytesToSize'
export * from './cookies'
export * from './getUTCOffset'