import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom';

import { Button, Card, CardHeader, CardBody, CardFooter,  Row, Col, UncontrolledAlert, Modal, ModalHeader, ModalFooter } from "reactstrap"

import Filters from '../../components/Requests/Filters'
import Pagination from 'components/Pagination'

import Table from './Table'
import Search from './Search'

import { getAll, deleteOne, getQueryString } from 'services/ApiService'
import { useAlert } from "hooks"
import AlertModal from "components/AlertModal"
import config from "config";
import { getCookie } from "helpers";

function List () {
  const [loading, isLoading] = useState(true)
  const [isLoadingExcel, setIsLoadingExcel] = useState(false)

  const [total, setTotal] = useState(0)

  const [success, setSuccess] = useState(undefined)
  const [successText, setSuccessText] = useState(undefined)

  const [items, setItems] = useState([])

  const [showModal, setShowModal] = useState(false)
  const [modalContent, setModalContent] = useState({})

  const [showAlert, message, alert] = useAlert(false, '')

  // Opciones de paginacion, filtro y orden del listado
  const [currentOptions, setCurrentOptions] = useState({
    estado_id: [1, 4],
    sort: {
      field: 'createdAt',
      dir: -1
    },
    page: 1,
    search: ''
  })
  
   // Carga inicial de datos
   useEffect(() => {
    fetchData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const fetchData = async (newOptions) => {
    try {
      // Merge de las nuevas opciones con las viejas
      const options = Object.assign({}, currentOptions, newOptions)

      isLoading(true)

      // Hacemos la petición
      const res = await getAll('requests', options)

      // Recuperamos algunos datos de los resultados
      const { rows, count } = res
      setItems(rows)
      setTotal(count)

      // Guardamos las opciones
      setCurrentOptions(options)
    } catch(err) {
      setItems([])
      setTotal(0)
    } finally {
      // Terminamos la carga
      isLoading(false)
    }
  }

  const cancelRequest = async (id, confirmated) => {
    if (!confirmated) {
      return (
        setModalContent({
          title: '¿Estás seguro que deseas cancelar la solcitud?',
          id
        }),
        setShowModal(true)
      )
    }
    setShowModal(false)
    try {
      const res = await deleteOne('requests', id)
      if (res.status === 200) {
        const fixed = items.slice()
        const index = fixed.findIndex(item => item.id === id)
        fixed.splice(index, 1)
        return (
          setItems(fixed),
          setSuccessText('Éxito! La solicitud se ha eliminado correctamente.'),
          setSuccess(true)
        )
      } else {
        alert(true, 'Error! No se ha podido eliminar la solicitud #' + id)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const downloadExcel = async () => {
    try {
      setIsLoadingExcel(true)

      const queryString = getQueryString(currentOptions)

      const res = await fetch(`${ config.baseUrl }/requests/excel${ queryString }`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: getCookie('accessToken')
        },
      })

      const blob = await res.blob()
    
      const file = window.URL.createObjectURL(blob);

      window.location.assign(file);
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoadingExcel(false)
    }
  }

  const changeOrder = (field, dir) => {
    fetchData({
      sort: {
        field,
        dir
      }
    })
  }

  const changePage = (page) => {
    fetchData({
      page
    })
  }

  const changeFilter = (key, value) => {
    fetchData({
      [key]: value
    })
  }

  const search = (query) => {
    fetchData({
      search: query
    })
  }

  return (
    <div className="content">
      <AlertModal isOpen={showAlert} message={message} onClose={() => alert(false)} />
      <Modal isOpen={showModal} className='attach-modal modal-dialog-centered'>
        <ModalHeader>
          { modalContent.title }
        </ModalHeader>
        <ModalFooter>
          <Button style={{ backgroundColor: 'red' }} onClick={() => cancelRequest(modalContent.id, true)}>
            Confirmar
          </Button>
          <Button color="primary" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      <Row>
        <Col md={12}>
          <Search onSearch={ search } currentQuery={ currentOptions.search } />
          <Filters changeFilter={ changeFilter } currentOptions={ currentOptions } />
          <Card>
            <CardHeader>
              <Row>
                <Col md={8}></Col>
                <Col md={4} className="text-right">
                  <Link to={{ pathname: '/solicitudes/step-insurer-selection' }} className="btn btn-primary btn-round">
                    Nueva solicitud
                  </Link>
                </Col>
              </Row>
              {
                success &&
                <UncontrolledAlert color="success" className={ success !== undefined ? '' : 'hidden' }>
                    { successText || 'Éxito! La solicitud se ha guardado correctamente.'}
                </UncontrolledAlert>
              }
            </CardHeader>
            <CardBody>
              <Table items={ items } isLoading={ loading } onCancel={ cancelRequest } changeOrder={ changeOrder } currentOrder={ currentOptions.sort } />
            </CardBody>
            <CardFooter className="py-4">
              <Row>
                <Col sm={6} className="d-flex justify-content-center d-sm-block mb-4 mb-md-0">
                  <Button color="light" onClick={ downloadExcel }>
                    {
                      isLoadingExcel
                      ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      : <>Descargar Excel</>
                    }
                  </Button>
                </Col>
                <Col sm={6} className="d-flex justify-content-center d-sm-block">
                  <Pagination page={ currentOptions.page } count={ total } onChangePage={ changePage } />
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default List
