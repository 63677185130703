import React, { useState, useEffect } from 'react'

import { Row, Col, Input, Button, FormGroup, Label } from 'reactstrap'

import Required from 'components/Required'
import AttachModal from 'components/AttachModal'
import config from '../../config'
import { bytesToSize, getRequiredAttachs } from 'helpers'
import { attachs as attachsLabels } from 'services/attachs'

// Mime types aceptados por la aplicación
const accept = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf']

export default function (props) {
  const {
    insurer_id,
    sessions,
    files,
    setFiles,
    disabled = false,
    editable = true,
    previously_authorized = false,
    impairment = false,
    rebilling = false,
    exceptional = false,
    sports = false,
    setLoading: setExternalLoading
  } = props
  const [ attachModal, setAttachModal ] = useState({})
  const [ isLoading, setLoading ] = useState(false)
  const [ required, setRequired ] = useState([])
  const [ sizeError, setSizeError ] = useState(null)
  const [ emptyError, setEmptyError ] = useState(null)
  const [ typeError, setTypeError ] = useState(null)

  const handleFileChange = (name, fileList) => {
    // Si no se seleccionó ningún archivo, no hacemos nada
    if (!fileList.length) {
      return
    }

    if (!accept.includes(fileList[0].type)) {
      setTypeError(name)
      // Reseteamos el file input
      const fileInput = document.getElementById(`file_${ name }`)
      fileInput.value = ''
      return
    }

    // Controlamos el tamaño del archivo
    if (fileList[0].size > config.maxFileSize) {
      setSizeError(name)
      // Reseteamos el file input
      const fileInput = document.getElementById(`file_${ name }`)
      fileInput.value = ''
      return
    }

    if (fileList[0].size === 0) {
      setEmptyError(name)
      // Reseteamos el file input
      const fileInput = document.getElementById(`file_${ name }`)
      fileInput.value = ''
      return
    }

    console.log('Attachments', 'Se adjuntó un archivo')
    console.info('Attachments', fileList[0])

    setLoading(true)
    if (setExternalLoading) {
      setExternalLoading(true)
    }
    setFiles({ ...files, [name]: fileList[0] })

    setSizeError(null)
    setTypeError(null)

    setLoading(false)
    if (setExternalLoading) {
      setExternalLoading(false)
    }
  }

  const handleFilePreview = (name) => {
    try {
      setLoading(true)
      if (setExternalLoading) {
        setExternalLoading(true)
      }

      // Si no hay una url definida porque no viene de la BBDD o porque todavía
      // no se previsualizó, creamos una
      if (!files[name].url) {
        files[name].url = URL.createObjectURL(files[name])
      }

      // Los pdfs los abrimos en una nueva pestaña/ventana
      if (files[name].type === 'application/pdf') {
        window.open(files[name].url)
        return
      }

      // Para todo lo demás utilizamos el modal
      setAttachModal({
        show: true,
        url: files[name].url,
        title: attachsLabels[name]
      })
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
      if (setExternalLoading) {
        setExternalLoading(false)
      }
    }
  }

  const handleFileReplace = (name) => {
    const fileInput = document.getElementById(`file_${ name }`)
    fileInput.click()
  }

  const handleFileRemove = (name) => {
    try {
      setLoading(true)

      // Reseteamos el file input
      const fileInput = document.getElementById(`file_${ name }`)
      fileInput.value = ''

      // Si tiene una vista previa generada, la borramos
      if (files[name].url && files[name].url.substring(0, 4) === 'blob') {
        URL.revokeObjectURL(files[name].url)
      }

      // Quitamos el archivo del state
      setFiles({
        ...files,
        [name]: undefined
      })
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  const getRequiredValue = (key) => {
    // COMEI y CASA
    if ((insurer_id === 22 || insurer_id === 47) && key === 'historia_clinica') {
      return false
    }

    // Dorso orden
    if (key === 'dorso_orden') {
      return false
    }
    
    return true
  }

  useEffect(() => {
    setLoading(disabled)
  }, [disabled])

  useEffect(() => {
    if (!editable) {
      setRequired(Object.keys(files))
      return
    }

    let newRequired = required

    switch(insurer_id) {
      case 86: { // OPDEA
        let bonos = []
        for (let i = 1; i <= sessions; i++) {
          bonos.push(`bono_${ i }`)
        }

        newRequired = [
          ...getRequiredAttachs(insurer_id),
          ...bonos
        ]
        break;
      }
      default:
        newRequired = getRequiredAttachs(insurer_id)
    }

    newRequired = newRequired.filter(r => r !== 'certificado_discapacidad')
    if (impairment) {
      newRequired.push('certificado_discapacidad')
    }

    if (previously_authorized) {
      newRequired.unshift('autorizacion')
    }

    if (exceptional) {
      newRequired.push('exceptional')
    }

    if (rebilling) {
      newRequired.push('nota_debito')
    }

    if (sports) {
      newRequired.push('constancia_deportiva')
    }

    setRequired(newRequired)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insurer_id, sessions, previously_authorized, impairment, rebilling, exceptional, sports])

  return (
    <>
      <FormGroup className={ props.className  }>
        <Label>
          Adjuntos<br />
          <small className='mt-2 text-muted'>Tamaño máximo por archivo: <strong>{ bytesToSize(config.maxFileSize) }</strong></small>
        </Label>
        <Row>
          {
            required.map(key => (
              <Col sm={ 6 } key={ key } className="mt-2">
                <label>{ attachsLabels[key] } { getRequiredValue(key) ? <Required /> : null}</label>
                <div className={ files[key] ? 'd-block' : 'd-none' }>
                  <Button color="primary" onClick={ () => handleFilePreview(key) } disabled={ isLoading }>
                    Ver adjunto
                  </Button>
                  {
                    editable
                    ? <>
                        <Button color="warning" onClick={ () => handleFileReplace(key) } disabled={ isLoading }>
                          Reemplazar
                        </Button>
                        <Button onClick={ () => handleFileRemove(key) } disabled={ isLoading }>
                          Eliminar
                        </Button>
                      </>
                    : null
                  }
                </div>
                {
                  editable
                  ? <div className={`custom-file ${ files[key] ? 'd-none' : '' }`}>
                      <Input
                        id={`file_${ key }`}
                        name={`file_${ key }`}
                        data-key={ key }
                        type="file"
                        accept={ accept.join(', ') }
                        onChange={ event => handleFileChange(key, event.target.files) }
                        className="custom-file-input overflow-hidden"
                        disabled={ isLoading }
                        required={ getRequiredValue(key) }
                      />
                      <label className="custom-file-label" htmlFor={`file_${ key }`}>Elegir archivo...</label>
                      <label className={`required-text mt-2 ${ sizeError !== key ? 'hidden' : ''}`} style={{ fontSize: '80%' }}>El tamaño del archivo no debe superar los 3 MB</label>
                      <label className={`required-text mt-2 ${ emptyError !== key ? 'hidden' : ''}`} style={{ fontSize: '80%' }}>El archivo que está intentando subir está vacio. Verifique que no sea un enlace directo y que sea válido</label>
                      <label className={`required-text mt-2 ${ typeError !== key ? 'hidden' : ''}`} style={{ fontSize: '80%' }}>El formato del archivo no está soportado. Únicamente se pueden adjuntar imágenes JPG y PNG o documentos PDF</label>
                    </div>
                  : null
                }
              </Col>
            ))
          }
        </Row>
      </FormGroup>

      <AttachModal data={ attachModal } onClose={ () => setAttachModal({ ...attachModal, show: false }) } />
    </>
  )
}