import React, { useState, useEffect, useContext } from 'react';
import { FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import DatePicker from 'components/DatePicker';
import AlertModal from 'components/AlertModal';
import { post } from 'services/ApiService';
import { useAlertPlus } from 'hooks';
import { getUTCOffset } from 'helpers';
import config from 'config';
import { getCookie } from 'helpers';
import moment from 'moment';
import { InsurerPractices, Request, Session } from 'types/Request';
import { UserContext } from 'context';
import { useHistory } from 'react-router-dom';

const insurersWithToken = [
  57, // Swiss
];

const insurersWithCoinsurance = [
  57, // Swiss
  77, // OSPe
];

const insurersWithPreviousAuthorization = [
  57, // Swiss
  77, // OSPe
];


interface SessionsProps {
  requestId: string;
  formData: Request;
  prestaciones: InsurerPractices;
  isLoading: boolean;
  disableSend: boolean;

  setLoading: (value: boolean) => void;
  setSessions: (sessions: Session[]) => void;
  setRecipient: (name: string) => void;
}

export default function Sessions(props: SessionsProps) {
  const { setSessions, formData, isLoading, setLoading, disableSend, requestId, setRecipient, prestaciones } = props;

  const user = useContext(UserContext);
  const history = useHistory();

  const [showAlert, message, alertCallback, alert] = useAlertPlus('');

  const [isEnabled, setEnabled] = useState(false);

  const [havePreviousAuthorization, setHavePreviousAuthorization] = useState(false);
  const [requiredPreviousAuthorization, setRequiredPreviousAuthorization] = useState(false);
  const [enablePreviousAuthorization, setEnablePreviousAuthorization] = useState(false);
  const [previousAuthorizationLabel, setPreviousAuthorizationLabel] = useState('');

  const [showToken, setShowToken] = useState(false);
  const [showCoinsurance, setShowCoinsurance] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [isOriginal, setIsOriginal] = useState(false);
  const [newSessionMinDate, setNewSessionMinDate] = useState<Date>();
  const [newSession, setNewSession] = useState<{
    date: Date | null;
    token: string | null;
    authorization_id: string | null;
  }>({
    date: null,
    token: null,
    authorization_id: null,
  });
  const [quotaExceeded, setQuotaExceeded] = useState(false);

  useEffect(() => {
    switch (formData.insurer_id) {
      case 57:
        setPreviousAuthorizationLabel('Autorización Previa (Para Especialidades)');
        break;
      case 77:
        setPreviousAuthorizationLabel('Validado por OSPE');
        break;
      default:
        setPreviousAuthorizationLabel('Autorización Previa');
    }
  }, [formData.insurer_id]);

  useEffect(() => {
    let withoutPrevAuth = false; // Permite desactivar en casos específicos la autorización previa
    let withToken = insurersWithToken.includes(formData.insurer_id);
    switch (formData.insurer_id) {
      case 57:
        // Si no hay prácticas seleccionadas no hay controles para realizar
        if (!formData.practice_id) {
          break;
        }

        // Swiss Medical no puede utilizar autorización previa para Módulo Consultorio (id 7), Reeducación Postural ni Drenaje Linfático ni Práctica Domicilio (id 13)
        if ([7, 13, 38, 39, 40, 15, 25].includes(formData.practice_id)) {
          withoutPrevAuth = true;
        }

        // No se tiene que pedir token para prácticas de internación
        if (formData.practice_id === 14 || formData.additional_id === 14) {
          withToken = false;
        }
        break;
      case 66: // Sancor
        withoutPrevAuth = false;
        break;
      case 74: // Federada
        withToken = true;
        withoutPrevAuth = false;
        break;
      case 77: // OSPe
        if (quotaExceeded) {
          withoutPrevAuth = false;
        }
        break;
      default:
    }

    setHavePreviousAuthorization(insurersWithPreviousAuthorization.includes(formData.insurer_id) && !withoutPrevAuth);

    setShowCoinsurance(insurersWithCoinsurance.includes(formData.insurer_id));

    setShowToken(withToken);
  }, [formData.insurer_id, formData.practice_id, formData.additional_id, quotaExceeded]);

  useEffect(() => {
    /**
     * Prácticas:
     * - 7 -> Modulo Consultorio
     * - 9 -> Reeducación postural
     * - 10 -> Modulo Drenaje Linfático
     * - 38 -> Especialidad Ortopedia y Traumatologia
     * - 39 -> Estimulación temprana y psicomotricidad
     * - 40 -> Especialidad Deportologia
     * - 15 -> Especialidad Pediatria y Neonatologia
     * - 25 -> Especialidad Intensivista
     */
    if (formData.insurer_id === 57 && formData.practice_id && [9, 10].includes(formData.practice_id)) {
      setRequiredPreviousAuthorization(true);
      return;

    // Para las especialidades y orientaciones de OSPe es obligatoria la autorización previa
    } else if (formData.insurer_id === 77 && formData.practice_id !== 7) {
      setRequiredPreviousAuthorization(true);
      return;

    // Para el módulo consultorio es obligatoria la autorización previa si se excedió el cupo
    } else if (formData.insurer_id === 77 && formData.practice_id === 7 && quotaExceeded) {
      setRequiredPreviousAuthorization(true);
      return;
    } else if (
      formData.insurer_id === 77 &&
      (formData.additional_id && [13, 14].includes(formData.additional_id)) ||
      quotaExceeded) {
      setRequiredPreviousAuthorization(true);
      return;

    // Para las Prácticas Domicilio/Internación o falta de cupo de Federada es obligatoria la autorización previa
    }

    setRequiredPreviousAuthorization(false);
  }, [formData.insurer_id, formData.practice_id, quotaExceeded]);

  useEffect(() => {
    const enabled = !!formData.prescription_date && !!formData.recipient_id && !!formData.practice_id;
    setEnabled(enabled);
  }, [formData.prescription_date, formData.recipient_id, formData.practice_id]);

  // Cálculo de fecha mínima para el datepicker de sesiones
  useEffect(() => {
    let newMinDate: Date;

    switch (formData.insurer_id) {
      case 66:
      case 77:
        // Fecha mínima Sancor y OSPe 5 días
        newMinDate = new Date(Date.now() - 5 * 24 * 60 * 60 * 1000);
        break;
      case 57:
      case 74:
        // Fecha mínima: Swiss y Federada, 7 días
        newMinDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
        break;
      default:
        newMinDate = formData?.prescription_date ?? new Date();
    }

    // Si la fecha de prescripción es mayor a la fecha mínima, ajustamos la fecha mínima
    if (formData?.prescription_date && newMinDate.valueOf() - formData.prescription_date.valueOf() < 0) {
      newMinDate = formData.prescription_date;
    }

    setNewSessionMinDate(newMinDate);
  }, [formData.prescription_date, formData.insurer_id]);

  const handleSessionAuthorization = async () => {
    try {
      setLoading(true);

      let count = 0;
      const newSessionDate = moment(newSession.date);
      for (const currentSession of formData.sessions) {
        if (newSessionDate.isSame(currentSession.date, 'day')) {
          count += 1;
        }
      }

      let sessionsPerDay = 1;
      if (formData.insurer_id === 57 && (formData.practice_id === 14 || formData.additional_id === 14)) {
        sessionsPerDay = 2;
      }


      if (count > sessionsPerDay) {
        if (sessionsPerDay === 1) {
          alert('No se puede agregar más de una sesión por día');
        } else {
          alert(`No se puede agregar más de ${ sessionsPerDay } sesiones por día`);
        }
        return;
      }

      // Verificar que se haya ingresado el número de autorización en los casos que corresponda
      if (
        (requiredPreviousAuthorization || enablePreviousAuthorization) &&
        (!newSession?.authorization_id || !newSession.authorization_id?.trim())
      ) {
        alert('Ingrese el número de autorización previa en el cuadro de texto correspondiente e intente nuevamente.');
        return;
      }

      // Impide que se cree una sesión con el token vacío
      if (showToken && newSession.token?.trim() === '') {
        alert('Debe ingresar un token.');
        return;
      }

      // Impedir el uso del token de pruebas por parte de los matriculados
      if (showToken && newSession.token === '999') {
        alert('El número de token ingresado no es válido.');
        return;
      }

      const response = await post('insurers/authorize', {
        insurer_id: formData.insurer_id,
        request_id: requestId,

        prescriber_id: user?.getMatricula(),
        recipient_id: formData.recipient_id,

        practice_id: formData.practice_id,
        practice_text: prestaciones.modules.find(p => p.id === parseInt(`${ formData.practice_id }`))?.name,

        practice_authorization: newSession.authorization_id,
        practice_date: newSession.date?.toISOString().split('T')[0],
        practice_token: newSession.token,

        authorization_id: newSession.authorization_id,

        additional_id: formData.additional_id,
        additional_text: formData.additional_id
          ? prestaciones.additionals.find(p => p.id === parseInt(`${ formData.additional_id }`))?.name
          : null,

        diagnosis: formData.diagnosis,

        quirurgical: formData.quirurgical,

        prescription_date: formData.prescription_date?.toISOString().split('T')[0],
      });

      // Errores
      if (response.status !== 'ok') {
        if (response.code === 'REQUIRE_AUTHORIZATION') {
          setQuotaExceeded(true);
          alert(response.message);
          return;
        }

        if (response.message) {
          alert(response.message);
          return;
        }

        alert('Hubo un problema al intentar autorizar la práctica. Por favor, intente nuevamente más tarde');
        return;
      }

      if (response.payload?.recipient?.name) {
        setRecipient(response.payload.recipient.name);
      }

      // Agregamos al arreglo de fechas de sesiones la nueva fecha
      setSessions([
        ...formData.sessions,
        {
          date: new Date(`${ response.payload.session.date }${ getUTCOffset() }`),
          authorization_id: response.payload.session.approvalNumber,
          coinsurance: response.payload.session.coinsurance,
        } as Session,
      ]);

      setOpen(false);

      if (response.payload.session.coinsurance > 0) {
        alert(`Coseguro a cargo del afiliado por sesión: $ ${ response.payload.session.coinsurance }`);
      }
    } catch (err) {
      console.error(err);
      alert('Hubo un problema al intentar autorizar la práctica. Por favor, intente nuevamente más tarde');
    } finally {
      setLoading(false);
    }
  };

  const handleSessionAnnulment = async (target: number) => {
    try {
      setLoading(true);

      const response = await post('insurers/annul', {
        request_id: requestId,
        insurer_id: formData.insurer_id,
        recipient_id: formData.recipient_id,
        authorization_id: formData.sessions[target].authorization_id,
      });

      // Errores
      if (response.status !== 'ok') {
        if (response.message) {
          alert(response.message);
        } else {
          alert('Hubo un problema al intentar anular la práctica. Por favor, intente nuevamente más tarde');
        }

        setLoading(false);
        return;
      }

      const sessions = formData.sessions.filter((value, idx) => idx !== target);

      setSessions(sessions);
    } catch (err) {
      console.error(err);
      alert('Hubo un problema al intentar validar el número de beneficiario. Por favor, intente nuevamente más tarde');
    } finally {
      setLoading(false);
    }
  };

  const showNewSessionModal = async () => {
    if (!isOriginal) {
      const response = await fetch(`${ config.baseUrl }/requests/check`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getCookie('accessToken'),
        },
        body: JSON.stringify({
          request_id: requestId,
          insurer_id: formData.insurer_id,
          recipient_id: formData.recipient_id,
          prescription_date: formData.prescription_date?.toISOString().split('T')[0],
        }),
      });

      const output = await response.json();

      if (output.status === 'ok' && output.payload) {
        if (output.payload.estado_id === 1 || output.payload.estado_id === 4) {
          alert(`Ya existe una solicitud en curso para este afiliado con esta fecha de prescripción. ` +
            `Si desea agregar más sesiones al tratamiento debe hacerlo en la misma o enviarla antes de ` +
            `crear una nueva. Ahora será redirigido a la solicitud #${ output.payload.id }`, () => {
            if (formData.insurer_id === 66) {
              history.replace(`/solicitudes/${ output.payload.id }/edit-sancor`);
            } else if (formData.insurer_id === 57) {
              history.replace(`/solicitudes/${ output.payload.id }/edit-swiss`);
            } else if (formData.insurer_id === 74) {
              history.replace(`/solicitudes/${ output.payload.id }/edit-federada`);
            }
            history.replace(`/solicitudes/${ output.payload.id }/edit-sessions`);
          });

          return;
        }
        if (output.payload.estado_id === 2) {
          alert(`1- Debe validar con otra Fecha de prescripción dado que ya se encuentra un en estado ` +
            `Solicitada nro. ${ output.payload.id } \n 2- En caso de querer mantener la solicitud nro. ` +
            `${ output.payload.id }, por favor contactar al auditor de la DR para que observe la misma.`);
          return;
        }
      }

      setIsOriginal(true);
    }

    // Si no hay fecha de sesión definida (no se cargó ninguna), tomamos la fecha
    // de la prescripción, si no tomamos el día siguiente a la última fecha
    if (!formData.sessions.length) {
      let newDate = formData.prescription_date;

      if (formData.insurer_id === 57 || formData.insurer_id === 66 || formData.insurer_id === 77) {
        newDate = new Date();
      }

      setNewSession({
        date: newDate ? newDate : null,
        token: null,
        authorization_id: null,
      });
    } else {
      const lastSessionDate = formData.sessions
        .map(({ date }) => date)
        .sort((a, b) => {
          return b.valueOf() - a.valueOf();
        })[0];
      if (lastSessionDate.getTime() + 60 * 60 * 24 * 1000 <= Date.now()) {
        setNewSession({
          ...newSession,
          date: new Date((new Date(lastSessionDate.getTime() + 60 * 60 * 24 * 1000)).setHours(0, 0, 0, 0)),
        });
      } else {
        setNewSession({
          ...newSession,
          date: new Date(new Date().setHours(0, 0, 0, 0)),
        });
      }
    }

    // Mostramos el modal
    setOpen(true);
  };

  return (
    <>
      <Button
        className="d-block"
        color="primary"
        onClick={ () => {
          showNewSessionModal();
        } }
        disabled={ isLoading || !isEnabled }
      >
        Agregar sesión
      </Button>
      <small style={{ color: '#e67f83' }} className={`mt-2 ${ formData?.prescription_date ? 'd-none' : 'd-block' }`}>
        Falta seleccionar la fecha de prescripción
      </small>
      <small style={{ color: '#e67f83' }} className={`mt-2 ${ formData?.recipient_id ? 'd-none' : 'd-block' }`}>
        Falta indicar el número de afiliado
      </small>
      <Table className='align-items-center table-flush mb-0' style={{ fontSize: '.8rem' }} responsive={ false }>
        <thead>
          <tr>
            <th>FECHA SESIÓN</th>
            <th>AUTORIZACIÓN</th>
            { showCoinsurance ? <th>COSEGURO</th> : null }
            <th>ACCIONES</th>
          </tr>
        </thead>
        <tbody>
          {
            formData && formData.sessions && Array.isArray(formData.sessions)
              ? formData.sessions
                .sort((a, b) => a.date.valueOf() - b.date.valueOf())
                .map(({ date, authorization_id: authorizationId, coinsurance }, i) => (
                  <tr key={ i }>
                    <td>{ date.toLocaleDateString('es') }</td>
                    <td>{ authorizationId ?? ' - ' }</td>
                    { showCoinsurance ? <td>$ { coinsurance ?? '0.00' }</td> : null }
                    <td className='text-left table__mobile--body__actions'>
                      {
                      // Sancor devuelve número de autorización 0 en Test
                        authorizationId || (formData.insurer_id === 66 && authorizationId !== '')
                          ? <Button
                              color="warning"
                              size="sm"
                              onClick={ () => handleSessionAnnulment(i) }
                              disabled={ isLoading }
                            >Anular</Button>
                          : null
                      }
                    </td>
                  </tr>
                ))
              : null
          }
        </tbody>
      </Table>
      <Modal isOpen={ isOpen } size="md" centered>
        <ModalHeader>Agregar fecha de sesión</ModalHeader>
        <ModalBody>
          <FormGroup className='card form-group-dates'>
            <Label>Fecha</Label>
            <DatePicker
              id="session_date"
              name="session_date"
              onChange={ date => {
                if (!date) {
                  date = new Date();
                }

                setNewSession({ ...newSession, date: new Date(date.setHours(0, 0, 0, 0)) });
              }}
              dateFormat="dd/MM/yyyy"
              locale="es"
              placeholderText="Fecha de la sesión"
              minDate={ newSessionMinDate }
              maxDate={ new Date() }
              excludeDates={ formData?.sessions.map(({ date }) => date) }
              selected={ newSession.date }
              className="form-control"
              autoComplete="off"
              disabled={ isLoading }
            />
          </FormGroup>
          {
            havePreviousAuthorization
              ? <FormGroup>
                  <div className="custom-control custom-checkbox">
                    <Input
                      id="session_have_authorization"
                      name="session_have_authorization"
                      type="checkbox"
                      checked={ requiredPreviousAuthorization || enablePreviousAuthorization }
                      onChange={ ({ target }) => setEnablePreviousAuthorization(target.checked) }
                      disabled={ isLoading }
                      style={{ marginTop: '.15rem' }}
                    />
                    <Label for="session_have_authorization" className="form-check-label">
                      { previousAuthorizationLabel }
                    </Label>
                  </div>
                  <Input
                    id="session_authorization_id"
                    name="session_authorization_id"
                    value={ newSession.authorization_id ?? '' }
                    onChange={ ({ target }) => setNewSession({ ...newSession, authorization_id: target.value }) }
                    disabled={ isLoading || (!requiredPreviousAuthorization && !enablePreviousAuthorization)}
                    required
                  />
                </FormGroup>
              : null
          }
          {
            showToken
              ? <FormGroup>
                  <Label for="session_token">Token de Seguridad</Label>
                  <Input
                    id="session_token"
                    name="session_token"
                    value={ newSession.token ?? '' }
                    onChange={ ({ target }) => setNewSession({ ...newSession, token: target.value.trim() }) }
                    disabled={ isLoading }
                    required
                  />
                </FormGroup>
              : null
          }
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            onClick={ () => handleSessionAuthorization() }
            disabled={ isLoading || disableSend }
          >
            { requiredPreviousAuthorization || enablePreviousAuthorization ? 'Validar' : 'Autorizar' }
          </Button>
          <Button
            color="primary"
            onClick={ () => setOpen(false) }
            disabled={ isLoading }
          >
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      <AlertModal isOpen={ showAlert } message={ message } onClose={ alertCallback } />
    </>
  );
}
